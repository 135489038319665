.MuiTableContainer-root {
  display: flex;
  flex-flow: column;
  flex: 1 1 auto;
}

.elevate-table-wrapper {
  display: flex;
  flex-flow: column;
  flex: 1 1 auto;
}
.elevate-table {
  border-collapse: separate;
  border-spacing: 0rem 0.35rem;

  transition-property: filter;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out
}
.elevate-table .MuiTableHead-root {
    transition-property: filter;
    transition-duration: 0.2s;
    transition-timing-function: ease-in-out;
  }
.elevate-table > .MuiTableBody-root .MuiSvgIcon-root path {
      fill: var(--app-box-border-color);
    }
.elevate-table > .MuiTableBody-root > .MuiTableRow-root:hover .MuiTableCell-body {
          border-color: #ccc;
          border-left-color: var(--app-box-border-color)
        }
.elevate-table > .MuiTableBody-root > .MuiTableRow-root:hover .MuiTableCell-body:first-of-type {
            border-left-color: #ccc;
          }
.elevate-table > .MuiTableBody-root > .MuiTableRow-root:hover .MuiTableCell-body:last-of-type {
            border-right-color: #ccc;
          }
.elevate-table > .MuiTableBody-root > .MuiTableRow-root:hover .MuiSvgIcon-root path {
          fill: var(--app-background-color-inverse);
        }
.elevate-table > .MuiTableBody-root > .MuiTableRow-root:hover.multiple-invoices-visible + .invoice-list > .MuiTableCell-body {
            border-color: var(--app-background-color-inverse);
          }
.elevate-table .MuiTableCell-root {
    padding: 1rem 0.4rem;
    font-family: var(--app-font-family)
  }
.elevate-table .MuiTableCell-root.MuiTableCell-head {
      background: var(--app-background-color-inverse);
      color: var(--text-color-inverse);
      padding: 0
    }
.elevate-table .MuiTableCell-root.MuiTableCell-head .elevate-table-header-cell-wrapper {
        display: flex;
        flex-flow: column;
        /* position: relative; */
      }
.elevate-table .MuiTableCell-root.MuiTableCell-head .elevate-table-header-cell-value {
        /* padding: 0.75rem 0.9rem; */
        padding: 0.1em 0.25em;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0.25rem 0.5rem
      }
.elevate-table .MuiTableCell-root.MuiTableCell-head .elevate-table-header-cell-value .elevate-table-header-cell-label {
          flex: 1 1 auto
        }
.elevate-table .MuiTableCell-root.MuiTableCell-head .elevate-table-header-cell-value .elevate-table-header-cell-label .elevate-table-header-cell-label-text {
            font-weight: 600;
          }
.elevate-table .MuiTableCell-root.MuiTableCell-head .elevate-table-header-cell-value .elevate-table-header-cell-tools {
          flex: 0 0 auto;
          display: inline-flex;
          align-items: center;
          justify-content: flex-end;
        }
.elevate-table .MuiTableCell-root.MuiTableCell-head .elevate-table-header-cell-value svg {
          flex: 0 0 1.5rem
        }
.elevate-table .MuiTableCell-root.MuiTableCell-head .elevate-table-header-cell-value svg path {
            fill: var(--text-color-inverse);
          }
.elevate-table .MuiTableCell-root.MuiTableCell-head .MuiTableSortLabel-root {
        padding: 0 0.25rem;
        color: var(--text-color-inverse);
        fill: var(--text-color-inverse);
        display: flex;
        font-weight: 600;
        justify-content: space-between;
        align-items: center
      }
.elevate-table .MuiTableCell-root.MuiTableCell-head .MuiTableSortLabel-root svg {
          flex: 0 0 1.5rem;
          opacity: 0.75;
        }
.elevate-table .MuiTableCell-root.MuiTableCell-head .MuiTableSortLabel-root.MuiTableSortLabel-active {
          /* font-weight: 600; */
        }
.elevate-table .MuiTableCell-root.MuiTableCell-head .MuiTableSortLabel-root.MuiTableSortLabel-active svg {
            opacity: 1;
          }
.elevate-table .MuiTableCell-root.MuiTableCell-head .MuiTableSortLabel-root:focus {
          text-decoration: underline;
          background: #0e5b8b;
        }
.elevate-table .MuiTableCell-root.MuiTableCell-head .MuiTableSortLabel-root:hover {
          background: #0e5b8b
        }
.elevate-table .MuiTableCell-root.MuiTableCell-head .MuiTableSortLabel-root:hover svg {
            opacity: 1;
          }
.elevate-table .MuiTableCell-root.MuiTableCell-head .elevate-table-header-cell-filters-wrapper {
        box-shadow: 0 0.125rem 0.25rem 0 rgba(0, 0, 0, 0.15);
        position: absolute;
        color: #6a6d6d;
        right: 0;
        /* left: 50%; */
        transform: translateX(50%);
        z-index: 3;
        top: 100%;
        background: #cc00dd;
        min-width: 10ch;
        background: #fff;
        border-radius: 0.25rem;
        border: 1px solid #e2e5e9;
        border-bottom: none;
      }
.elevate-table .MuiTableCell-root.MuiTableCell-head.elevate-table-sortable-header-cell {
        /* padding: 0; */
      }
.elevate-table .MuiTableCell-root.MuiTableCell-head.elevate-table-sortable-header-cell .MuiTableSortLabel-root {
          padding: 0.5rem 0.5rem;
        }
.elevate-table .MuiTableCell-root.MuiTableCell-head.elevate-table-sortable-header-cell:hover {
          background: #0e5b8b;
        }
.elevate-table .MuiTableCell-root.MuiTableCell-head.elevate-table-header-cell-sorted {
        background: #0e5b8b;
      }
.elevate-table .MuiTableCell-root.MuiTableCell-head:first-of-type {
        border-radius: 0.25em 0 0 0.25em;
      }
.elevate-table .MuiTableCell-root.MuiTableCell-head:last-of-type {
        border-radius: 0 0.25em 0.25em 0;
      }
.elevate-table .MuiTableCell-root.MuiTableCell-body {
      border: 1px solid #ccc;
      border-right: none;
      font-weight: 600
    }
.elevate-table .MuiTableCell-root.MuiTableCell-body.order-name {
        white-space: nowrap;
      }
.elevate-table .MuiTableCell-root.MuiTableCell-body:first-of-type {
        border-radius: 0.25rem 0 0 0.25rem;
      }
.elevate-table .MuiTableCell-root.MuiTableCell-body:last-of-type {
        border-right: 1px solid #ccc;
        border-radius: 0 0.25rem 0.25rem 0;
      }
.elevate-table .elevate-tr {
    transition-property: filter, opacity;
    transition-duration: 0.2s;
    transition-timing-function: ease-in-out;
  }
.elevate-table .MuiTableRow-head {
    transition-property: opacity;
    transition-duration: 0.2s;
    transition-timing-function: ease-in-out;
  }
.elevate-table .elevate-table-column {
  }
.elevate-table .MuiTableFooter-root .MuiTableRow-footer {
      background: #fff
    }
.elevate-table .MuiTableFooter-root .MuiTableRow-footer .MuiTableCell-footer {
        border: 1px solid #ccc;
        border-radius: 0.25rem;
      }
.elevate-table.MuiTable-stickyHeader {
    border-spacing: 0rem 0.35rem
  }
.elevate-table.MuiTable-stickyHeader .MuiTableFooter-root {
      position: sticky;
      bottom: 0;
    }
.elevate-table .MuiTableCell-stickyHeader {
    z-index: 1;
  }
.elevate-table .MuiTableHead-root + tfoot {
    display: table-header-group;
  }
.elevate-table.elevate-table-loading {
    cursor: progress
  }
.elevate-table.elevate-table-loading .elevate-tr {
      opacity: 0.8;
      pointer-events: none;
      filter: blur(0.125rem);
    }
.elevate-table.elevate-table-loading .MuiTableHead-root {
      filter: contrast(0.75) opacity(0.9);
      pointer-events: none;
      position: relative;
      z-index: 1;
    }
.elevate-table.elevate-table-loading .MuiTableFooter-root {
      opacity: 1;
      pointer-events: none;
    }
.elevate-table.elevate-table-loading .elevate-table-loading-body-title,
    .elevate-table.elevate-table-loading .elevate-table-loading-body-message {
      text-align: center;
      padding: 0.5rem;
      font-size: 1.8em;
      font-weight: 100
    }
.elevate-table.elevate-table-loading .elevate-table-loading-body-title .spinner-wrapper, .elevate-table.elevate-table-loading .elevate-table-loading-body-message .spinner-wrapper {
        text-align: center;
      }
.elevate-table .elevate-table-error-body-title {
    text-align: center;
    padding: 0.5rem;
    font-size: 1.5em;
  }
.elevate-table .elevate-table-error-body-message {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-flow: column;
    padding: 0.5rem;
    font-size: 1.2em;
    min-height: 4em;
  }
.elevate-table.elevate-table-loader {
    height: 100%;
    flex: 1 1 auto
  }
.elevate-table.elevate-table-loader .elevate-table-header-cell {
      padding: 1.5rem;
      text-align: center;
    }

.elevate-table-pagination {
  display: flex;
  align-items: center
}

.elevate-table-pagination .elevate-table-pagination-left {
    flex: 1 1 auto;
    display: flex;
    align-items: center
  }

.elevate-table-pagination .elevate-table-pagination-left .elevate-table-pagination-rows {
      margin-left: 1rem
    }

.elevate-table-pagination .elevate-table-pagination-left .elevate-table-pagination-rows label {
        color: #ffffff;
        margin-left: 0.6rem;
      }

.elevate-table-pagination .elevate-table-pagination-left .elevate-table-pagination-rows .items-per-page-select {
        background: #ffffff
      }

.elevate-table-pagination .elevate-table-pagination-left .elevate-table-pagination-rows .items-per-page-select.Mui-focused {
          background: #fff;
        }

.elevate-table-pagination .elevate-table-pagination-right {
    flex: 0 1 auto;
    font-size: 1.25em;
    font-weight: 600;
    text-transform: capitalize;
    color: #000;
    padding-right: 0.125rem;
  }

.elevate-table-pagination .MuiButtonBase-root {
    border: 2px solid transparent
  }

.elevate-table-pagination .MuiButtonBase-root:hover {
      border: 2px solid #147cbd;
      color: #147cbd;
      background: none;
    }

.elevate-table-pagination .MuiButtonBase-root.Mui-selected {
      border: 2px solid #147cbd;
      background: #fff
    }

.elevate-table-pagination .MuiButtonBase-root.Mui-selected:hover {
        box-shadow: 0 0 3px #147cbd;
      }

.elevate-table-restore {
  display: flex;
  align-items: center;
  height: 3rem;
  position: sticky
}

.elevate-table-restore .elevate-table-restore-left {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    font-size: 1rem;
    color: black;
    margin-left: 0.5rem;
  }

.elevate-table-restore .elevate-table-restore-right {
    font-size: 1.25em;
    display: flex;
    padding-right: 0.5rem;
    gap: 0.5em;
  }
