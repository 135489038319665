html,
body {
  margin: 0;
  padding: 0;
  font-size: 100%;
  font-family: var(--app-font-family);
  -webkit-font-smoothing: subpixel-antialiased;
  background-color: var(--page-background-color);
  text-rendering: optimizeLegibility;
}

body * {
  font-family: var(--app-font-family);
  -webkit-font-smoothing: subpixel-antialiased;
}

h1,
h2,
h3 {
  margin: 0;
}

#root {
  padding: 0;
  margin: 0;
}

.app-page .app-layout-root {
    /* max-height: calc(100vh - 0.125em); */
    /* max-height: calc(100vh - 0.0625em); */

    min-width: 768px;
    max-width: 100%;
    display: flex;
    flex-flow: column;
    transition-property: filter, max-width;
    transition-duration: var(--default-transition-duration);
    transition-timing-function: var(--default-transition-timing-function)
  }

.app-page .app-layout-root .page-wrapper {
      display: flex;
      flex-flow: column;
      flex: 1 1 auto;
      margin-top: 0em;
      width: 100%;

      transition-property: margin, width, max-width, padding, height, max-height;
      transition-timing-function: var(--default-transition-timing-function);
      transition-duration: var(--default-transition-duration);
    }

.app-page .app-layout-root .app-error-root {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 100%;

      background: rgba(255, 255, 255, 0.9);
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: center;
      z-index: 4;

      transition-property: top;
      transition-duration: var(--default-transition-duration);
      transition-timing-function: var(--default-transition-timing-function)
    }

.app-page .app-layout-root .app-error-root .app-error-title {
        flex: 0 1 auto;
        font-size: 2.6em;
        font-weight: 500;
        color: #d9252b;
      }

.app-page .app-layout-root .app-error-root .app-error-body {
        flex: 0 1 auto;
        margin: 1em 0;
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center
      }

.app-page .app-layout-root .app-error-root .app-error-body .app-error-icon {
          border-radius: 50%;
          width: 5em;
          height: 5em;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 2em auto;
          /* padding: 0.4em; */
          color: #d9252b;
        }

.app-page .app-layout-root .app-error-root .app-error-body .app-error-message {
          margin: 1.5em 0;
        }

.app-page .app-layout-root .app-error-root .app-error-footer {
        flex: 0 1 auto;
        margin: 1em 0;
        display: flex;
        align-items: center;
        justify-content: center;
      }

.app-page .suspense-overlay-loader,
  .app-page .app-loader-root {
    z-index: 5;
    height: 100%;
    width: 100%;
    margin-top: 0;
    margin-left: 0;
    /* height: calc(100vh - var(--app-header-height));
    margin-top: var(--app-header-height); */

    transition-property: margin-top, height, margin-left, width;
    transition-timing-function: var(--default-transition-timing-function);
    transition-duration: var(--default-transition-duration)
  }

.app-page .suspense-overlay-loader.redirect-loader-root, .app-page .app-loader-root.redirect-loader-root {
      background: rgba(255, 255, 255, 1);
      text-align: center;
    }

.app-page .suspense-overlay-loader.session-loader-root, .app-page .app-loader-root.session-loader-root {
      background: rgba(255, 255, 255, 1);
    }

.app-page.has-app-header .suspense-overlay-loader,
    .app-page.has-app-header .app-error-root,
    .app-page.has-app-header .app-loader-root {
      height: calc(100% - var(--app-header-height));
      margin-top: var(--app-header-height);
    }

.app-page.has-app-header {

    /* & .app-error-root {
      margin-top: var(--app-header-height);
    } */
  }

.app-page.has-app-header header.app-header.has-visible-menu + .page-wrapper {
          margin-left: calc(var(--app-menu-width) + 0.5rem);
          width: calc(100% - var(--app-menu-width) - 0.5rem)
        }

.app-page.has-app-header header.app-header.has-visible-menu + .page-wrapper + .app-loader-root {
            margin-left: var(--app-menu-width);
            width: calc(100% - var(--app-menu-width) - 0.5rem);
          }

.app-page.has-app-header header.app-header.has-visible-menu.has-expanded-menu + .page-wrapper {
            margin-left: calc(var(--app-menu-expanded-width) + 0.5rem);
            width: calc(100% - var(--app-menu-expanded-width) - 0.5rem)
          }

.app-page.has-app-header header.app-header.has-visible-menu.has-expanded-menu + .page-wrapper + .app-loader-root {
              margin-left: var(--app-menu-expanded-width);
              width: calc(100% - var(--app-menu-expanded-width) - 0.5rem);
            }

.app-page.has-app-header.has-app-header-toolbar .suspense-overlay-loader,
      .app-page.has-app-header.has-app-header-toolbar .app-error-root,
      .app-page.has-app-header.has-app-header-toolbar .app-loader-root {
        margin-top: calc(var(--app-header-height) + var(--app-toolbar-height));
        height: calc(100vh - (var(--app-header-height) - var(--app-toolbar-height)));
      }

.app-page.has-app-header.has-app-error-fatal .app-error-root {
        margin-top: 0em;
        height: 100vh;
      }

.app-page {

  /* &.app-overlay-visible {
    & .app-layout-root {
      filter: saturate(0.3) blur(2px); 
    }
  } */
}

.app-page.app-loading-user .suspense-overlay-loader,
    .app-page.app-loading-user .app-loader-root {
      height: calc(100vh - var(--app-header-height));
      margin-top: var(--app-header-height);
      background: rgba(255, 255, 255, 0.96);
    }

.app-page.has-app-debug .app-layout-root {
      max-width: calc(100% - var(--app-debug-width));
      /* max-width: calc(100vw - (1em + var(--app-debug-width))); */
    }

.app-page.has-app-debug .app-error-root,
    .app-page.has-app-debug .app-loader-root,
    .app-page.has-app-debug .suspense-overlay-loader {
      width: calc(100% - var(--app-debug-width));
    }

.app-page.has-app-debug.has-app-header header.app-header.has-visible-menu + .page-wrapper {
            /* margin-left: calc(var(--app-menu-width) + 0.5rem); */
            /* width: calc(100% - var(--app-menu-width) - 0.5rem - var(--app-debug-width)); */
          }

.app-page.has-app-debug.has-app-header header.app-header.has-visible-menu + .page-wrapper + .app-loader-root {
              margin-left: var(--app-menu-width);
              width: calc(100% - var(--app-menu-width) - 0.5rem - var(--app-debug-width));
            }

.app-page.has-app-debug.has-app-header header.app-header.has-visible-menu.has-expanded-menu + .page-wrapper {
              /* margin-left: calc(var(--app-menu-expanded-width) + 0.5rem); */
              /* width: calc(100% - var(--app-menu-expanded-width) - 0.5rem - var(--app-debug-width)); */
            }

.app-page.has-app-debug.has-app-header header.app-header.has-visible-menu.has-expanded-menu + .page-wrapper + .app-loader-root {
                margin-left: var(--app-menu-expanded-width);
                width: calc(
                  100% - var(--app-menu-expanded-width) - 0.5rem - var(--app-debug-width)
                );
              }

.app-page.has-app-debug.has-app-header.has-app-header-toolbar .suspense-overlay-loader,
        .app-page.has-app-debug.has-app-header.has-app-header-toolbar .app-error-root,
        .app-page.has-app-debug.has-app-header.has-app-header-toolbar .app-loader-root {
          margin-top: calc(var(--app-header-height) + var(--app-toolbar-height));
          height: calc(100vh - (var(--app-header-height) - var(--app-toolbar-height)));
        }

.app-page .app-layout-teacher-login .section-loader-root {
      display: none;
    }

.app-page.terms-not-accepted .app-layout-root .page-wrapper .page-root {
          pointer-events: none;
        }

.app-page.has-app-error-fatal .app-error-root {
      margin-top: 0;
      height: 100%;
      z-index: 9;
    }

.app-page .app-loader-title {
    font-size: 2em;
    color: var(--link-color);
  }

.app-page.has-sticky-app-notification .app-layout-root > .page-wrapper {
        margin-top: 5em
      }

.app-page.has-sticky-app-notification .app-layout-root > .page-wrapper.auth-page {
          margin-top: 0;
        }

.app-header-avatar-wrapper .app-header-avatar {
    overflow: hidden;
    width: 3.125em;
    height: 3.125em;
  }

.modal-dialog-wrapper {
  backdrop-filter: saturate(0.3) blur(2px);
}
