
  .terms-privacy-modal .modal-dialog-contents {
    max-width: 95vw
  }
.terms-privacy-modal .modal-dialog-contents .modal-dialog-title {
      flex: 1 0 auto;
      text-align: center;
      color: #0081c5;
      font-weight: 300;
      height: 2em;
    }
.terms-privacy-modal .modal-dialog-contents .modal-dialog-body {
      max-height: calc(80vh - 9.25em);

      /*& .terms-privacy-accept {
        & .terms-privacy-accept-body {

          & .elevate-privacy {
          }
        }
      }*/
    }
.terms-privacy-modal .modal-dialog-contents .modal-dialog-footer {
      padding: 0
    }
.terms-privacy-modal .modal-dialog-contents .modal-dialog-footer .terms-privacy-modal-footer {
        height: 6em;
        display: flex;
        flex-flow: column;
        justify-content: flex-end
      }
.terms-privacy-modal .modal-dialog-contents .modal-dialog-footer .terms-privacy-modal-footer .terms-modal-footnote {
          box-shadow: 0 -0.75em 0.75em #fff;
          border-top: var(--app-box-border);
          border-bottom: var(--app-box-border);
          border-bottom-color: var(--link-color);
          display: grid;
          grid-template-columns: 4ch auto;
          grid-column-gap: 0.6em;
          align-items: center;
          justify-content: flex-start;
          font-size: 0.9em;
          grid-template-rows: 1fr;
          flex: 0 0 2.5em
        }
.terms-privacy-modal .modal-dialog-contents .modal-dialog-footer .terms-privacy-modal-footer .terms-modal-footnote .footnote-index {
            color: #096da9;
            text-align: right;
          }
.terms-privacy-modal .modal-dialog-contents .modal-dialog-footer .terms-privacy-modal-footer .terms-modal-footnote .footnote-text {
            font-weight: 400;
          }
.terms-privacy-modal .modal-dialog-contents .modal-dialog-footer .terms-privacy-modal-footer .terms-modal-buttons {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          flex: 0 0 3em;
          padding-top: 0.5em
        }
.terms-privacy-modal .modal-dialog-contents .modal-dialog-footer .terms-privacy-modal-footer .terms-modal-buttons .terms-privacy-footer-message {
            margin: 0 0.5em;
          }
.terms-privacy-modal .modal-dialog-contents .modal-dialog-footer .terms-privacy-modal-footer .terms-modal-buttons .decline-terms-button,
          .terms-privacy-modal .modal-dialog-contents .modal-dialog-footer .terms-privacy-modal-footer .terms-modal-buttons .accept-terms-button {
            text-transform: none;
          }
.terms-privacy-modal .modal-dialog-contents .modal-dialog-footer .terms-privacy-modal-footer .terms-modal-buttons .decline-terms-button {
            color: var(--link-color);
            margin-right: 0.5em;
          }
