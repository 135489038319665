
  .app-layout-app-error .elevate-header-toolbar-wrapper {
    display: none;
  }
.app-layout-app-error .app-header .app-header-section {
      visibility: hidden
    }
.app-layout-app-error .app-header .app-header-section.app-header-navigation {
        visibility: visible
      }
.app-layout-app-error .app-header .app-header-section.app-header-navigation .app-header-bar {
          border-right-color: transparent;
        }
