.elevate-terms {
  font-weight: 300;
  padding: 0.3em 0.6em
}
.elevate-terms h2,
  .elevate-terms h3 {
    text-align: center;
  }
.elevate-terms h3 {
    margin-top: 1.2em;
  }
.elevate-terms h4 {
    padding-left: 4em;
    margin-bottom: 0.4em;
  }
.elevate-terms .article-title {
    /* color: #096da9; */
    color: var(--link-color);
    font-size: 3em;
    font-weight: 400;
  }
.elevate-terms .last-content-update {
    text-align: center;
    font-weight: 700;
  }
.elevate-terms .terms-definitions {
    list-style: none;
    margin: 1em 1em 2em 0;
    text-align: left
  }
.elevate-terms .terms-definitions li {
      display: grid;
      grid-template-columns: 15ch auto;
      grid-column-gap: 0.5em;
      align-items: flex-start;
      padding: 0.3em 0
    }
.elevate-terms .terms-definitions li strong {
        text-align: right;
      }
.elevate-terms p {
    display: block;
    text-align: justify;
  }
.elevate-terms a {
    /* color: #096da9; */
    color: var(--link-color)
  }
.elevate-terms a:hover {
      /* color: #333333; */
    }
