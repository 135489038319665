.app-form-row-buttons,
.app-form-row-submit {
  margin-top: 1em;
  text-align: center;
}

.app-button {
  padding: 0.7em 1.8em;
  color: var(--app-button-text-color);
  background: var(--app-button-background-color);
  border: var(--app-button-border);
  border-radius: var(--app-button-border-radius);
  letter-spacing: 0px;
  text-decoration: none
}

.app-button.MuiButtonBase-root {
    text-transform: unset
  }

.app-button.MuiButtonBase-root.MuiButton-contained {
      border: 1px solid transparent;
    }

.app-button.app-icon-button {
    display: inline-grid;
    grid-template-columns: repeat(2, auto);
    grid-column-gap: 1ch;
    align-items: center;
    color: var(--text-color-inverse)
  }

.app-button.app-icon-button img {
      height: 1.2em;
      opacity: 0.8;
      margin-left: 1ch;
    }

.app-button.app-icon-button path {
      fill: var(--text-color-inverse);
    }

.app-button.app-button-light {
    background: none;
    fill: var(--text-color-inverse);
  }

.app-button:hover {
    background: var(--app-button-hover-background-color);
  }

.app-button[disabled],
  .app-button.app-button-disabled {
    color: var(--app-button-disabled-text-color);
    background: var(--app-button-disabled-background-color);
    border: var(--app-button-disabled-border)
  }

.app-button[disabled]:hover, .app-button.app-button-disabled:hover {
      background: var(--app-button-disabled-hover-background-color);
    }

.app-button.app-button-small {
    padding: 0.3em 1em;
  }

.input-field {
  outline: none;
  background: var(--input-background);
  border: var(--input-border);
  border-radius: var(--input-border-radius);
  padding: 12px 5px;
  border-radius: 4px;
  margin-bottom: 1em;
  margin-top: 1.5em;

  transition-property: color, background, border;
  transition-duration: var(--animation-short);
  transition-timing-function: ease-in-out
}

.input-field:required:invalid {
      background: var(--input-background-invalid);
      border: var(--input-border-invalid);
    }

.input-field:required:valid {
      background: var(--input-background-valid);
      border: var(--input-border-valid);
    }

.input-field:required:placeholder-shown,
    .input-field:required:focus {
      box-shadow: none;
      background: var(--input-background);
      border: var(--input-border);
    }
