
    .app-page .session-timeout-modal .modal-dialog-mask {
      background: rgba(0, 0, 0, 0.5)
    }
.app-page .session-timeout-modal .modal-dialog-mask .modal-dialog-contents {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 32px;
        min-width: 25vw
      }
.app-page .session-timeout-modal .modal-dialog-mask .modal-dialog-contents .modal-dialog-title {
          margin-left: 0;
        }
.app-page .session-timeout-modal .modal-dialog-mask .modal-dialog-contents .modal-dialog-body .session-timeout-modal-body {
            text-align: center;
            margin: 24px
          }
.app-page .session-timeout-modal .modal-dialog-mask .modal-dialog-contents .modal-dialog-body .session-timeout-modal-body p {
              margin: 8px;
            }
.app-page .session-timeout-modal .modal-dialog-mask .modal-dialog-contents .modal-dialog-body .session-timeout-modal-body .time-left {
              font-weight: 600;
              color: #096da9;
            }
.app-page .session-timeout-modal .modal-dialog-mask .modal-dialog-contents .modal-dialog-footer .session-timeout-modal-footer {
            display: flex;
            gap: 1rem;
          }
