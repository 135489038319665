.dropdown-container-custom {
  position: relative;
  color: #6a6d6d;
  font-weight: 600;
  font-size: 12px;
  user-select: none;
}
.dropdown-container-custom.disabled {
  pointer-events: none;
  opacity: 0.5;
}
.dropdown-header-custom {
  background-color: #fff;
  padding: 0 32px 0 16px;
  line-height: 40px;
  border: 1px solid #d7dae2;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.05s linear;
  white-space: nowrap;
}
.dropdown-header-custom:hover {
  border: 1px solid #e2e5e9;
  background-color: #f6f6f6;
}
.dropdown-header-custom.expanded {
  border: 1px solid #2a80b4;
  background-color: #fff;
}
.dropdown-header-custom .placeholder {
  /*color: #ccc;*/
  color: #6a6d6d;
}
.dropdown-header-custom svg {
  position: absolute;
  right: 15px;
  top: 18px;
  transition: all 0.05s linear;
}
.dropdown-header-custom svg path {
  fill: #6a6d6d;
}
.dropdown-header-custom.expanded svg {
  transform: rotate(180deg);
}
.dropdown-container-custom .dropdown-list-container-custom {
  position: absolute;
  background-color: #fff;
  border: 1px solid #e2e5e9;
  border-bottom: 0px solid #d7dae2;
  cursor: default;
  min-width: 100%;
  z-index: 1;
}
.dropdown-list-container-custom ul {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.dropdown-list-container-custom li {
  list-style: none;
  padding: 0 32px 0 16px;
  margin: 0;
  line-height: 42px;
  border-bottom: 1px solid #d7dae2;
  transition: all 0.05s linear;
  position: relative;
  white-space: nowrap;
  cursor: pointer;
}
.dropdown-list-container-custom li.disabled {
  pointer-events: none;
  color: #aaa;
  cursor: default;
}
.dropdown-list-container-custom li:hover {
  color: #2a80b4;
  background-color: #f6f6f6;
}
.dropdown-list-container-custom li svg {
  position: absolute;
  top: 17px;
  right: 13px;
}
.dropdown-list-container-custom li svg path {
  fill: #6a6d6d;
}
