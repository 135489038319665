
  .assignment-only-global-styles .header-nav-icon-links {
    height: var(--app-header-height);
    display: flex;
    align-items: stretch
  }
.assignment-only-global-styles .header-nav-icon-links .header-nav-icon-link {
      height: inherit;
      width: 3.6em;
      display: flex;
      align-items: center;
      justify-content: center;
      border-right: 1px solid #2281b9
      /* background:#CCC; */
    }
.assignment-only-global-styles .header-nav-icon-links .header-nav-icon-link svg {
        width: 1.1em;
        height: 1.1em
      }
.assignment-only-global-styles .header-nav-icon-links .header-nav-icon-link svg path {
          fill: #d8e1e9;
        }
.assignment-only-global-styles .header-nav-icon-links .header-nav-icon-link:hover,
      .assignment-only-global-styles .header-nav-icon-links .header-nav-icon-link.active-header-nav-icon-link {
        background: #0e5b8b;
      }
