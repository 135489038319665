
  .app-action-wrapper .app-action-main {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center
  }
.app-action-wrapper .app-action-main .app-action-title {
      font-weight: 300;
      font-size: 1.8em;
      color: var(--app-background-color-inverse);
      margin: 1em 0 0 0;
    }
.app-action-wrapper .app-action-main .app-action-body {
      width: 100%;
      margin: 0.5em auto
    }
.app-action-wrapper .app-action-main .app-action-body .app-action-icon {
        text-align: center
      }
.app-action-wrapper .app-action-main .app-action-body .app-action-icon svg {
          width: 3em;
          height: 3em
        }
.app-action-wrapper .app-action-main .app-action-body .app-action-icon svg path {
            fill: var(--input-color-invalid);
          }
.app-action-wrapper .app-action-main .app-action-body .app-action-subtitle {
        font-weight: 400;
        font-size: 1.2em;
        margin: 1em 0 1em 0;
        text-align: center;
      }
.app-action-wrapper .app-action-main.app-action-main-error .app-action-title {
        font-weight: 600;
        font-size: 1.8em;
        color: var(--input-color-invalid);
        margin: 1em 0 0 0;
      }
.app-action-wrapper .app-action-footer-wrapper {
    margin: 0.5em auto;
    text-align: center;
  }
.app-action-wrapper .app-action-footer-buttons {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(auto-fit, minmax(10ch, auto));
    grid-gap: 0.4em 1em;
    align-items: center;
    justify-content: center;
  }
.app-action-wrapper.app-action-wrapper-stretch {
    flex: 1 0 100%;
  }
