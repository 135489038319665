.calendar-range-picker-root {
  align-self: flex-start;
  width: calc(100% - 2px);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 0.75em;
  border: 1px solid #d7dae2;
  border-radius: 0.375em;
  position: relative;
  background-color: #fff;
  margin-bottom: -0.1em
}

.calendar-range-picker-root .calendar-range-picker-field {
    display: flex;
    align-items: center;
    justify-content: center;
    /* gap: 0.2em; */
    padding: 0.4em 0.6em 0.4em 0.6em;
    font-size: inherit
  }

.calendar-range-picker-root .calendar-range-picker-field .range-picker-icon {
      cursor: pointer;
      flex: 0 1 auto;
      display: flex;
      align-items: center;
      margin-right: 0.4em
    }

.calendar-range-picker-root .calendar-range-picker-field .range-picker-icon svg {
        width: 1.125em;
        height: 1.125em
      }

.calendar-range-picker-root .calendar-range-picker-field .range-picker-icon svg path {
          fill: rgb(106, 109, 109);
        }

.calendar-range-picker-root .calendar-range-picker-field .test-dates-label {
      cursor: pointer;
      color: rgb(106, 109, 109);
      flex: 0 1 auto;
    }

.calendar-range-picker-root .calendar-range-picker-field .test-dates-values {
      flex: 0 1 auto
    }

.calendar-range-picker-root .calendar-range-picker-field .test-dates-values .test-date-input {
        font-weight: 600;
        color: #096da9;
        border: none;
        text-align: center;
        font-size: inherit;
        outline: none
      }

.calendar-range-picker-root .calendar-range-picker-field .test-dates-values .test-date-input:hover {
          outline: 1px solid #096da9;
        }

.calendar-range-picker-root .calendar-range-picker-field .test-dates-values .test-date-input:last-of-type {
          text-align: center;
        }

.calendar-range-picker-root .calendar-range-picker-field .test-dates-values .test-date-input-delimiter {
        padding: 0.3em auto;
      }

.calendar-range-picker-root .calendar-range-picker-field:hover .range-picker-icon path {
          fill: #147cbd;
        }

.calendar-range-picker-root .calendar-range-picker-field:hover .test-dates-label {
        color: #147cbd;
      }

.rdp {
  margin: 0.5em;
  font-size: 0.75em
}

.rdp .rdp-months {
    border-radius: 0.5em;
    border: 1px solid #ccc;
    background: #ffffff;
    padding: 0.6em
  }

.rdp .rdp-months .rdp-caption {
      margin: 0.8em 0
    }

.rdp .rdp-months .rdp-caption .rdp-caption_label {
        color: #6a6d6dcc;
        font-weight: 500;
        font-size: 1.15em;
      }

.rdp .rdp-months .rdp-caption .rdp-nav_button {
        width: 1.75em;
        height: 1.75em
      }

.rdp .rdp-months .rdp-caption .rdp-nav_button svg path {
            fill: #147cbd;
          }

.rdp .rdp-months .date-picker-footer {
      text-align: center;
      color: #881111;
      visibility: hidden;
    }

.rdp .rdp-months .rdp-head_cell {
      background: #ebf0f4;
      text-transform: unset;
      color: #757575;
      font-weight: 400;
      font-size: 0.9em;
    }

.rdp .rdp-months .rdp-day {
      border: 1px solid #ffffff66;
      border-left: none;
      border-top: none;
      border-radius: 0
    }

.rdp .rdp-months .rdp-day.rdp-day_selected.rdp-day_range_start,
        .rdp .rdp-months .rdp-day.rdp-day_selected.rdp-day_range_end {
          background: #2d6da4;
        }

.rdp .rdp-months .rdp-day.rdp-day_selected.rdp-day_range_start {
          border-radius: 0.5em 0 0 0.5em;
        }

.rdp .rdp-months .rdp-day.rdp-day_selected.rdp-day_range_end {
          border-radius: 0 0.5em 0.5em 0
        }

.rdp .rdp-months .rdp-day.rdp-day_selected.rdp-day_range_end.rdp-day_range_start {
            border-radius: 0.5em;
          }

.rdp .rdp-months .rdp-day:hover {
        background-color: #aacfec;
      }

.rdp .rdp-months .rdp-day.rdp-day_disabled {
        background-color: #ccc;
      }

.rdp .rdp-months.invalid-start-date .rdp-months .rdp-caption_start .date-picker-footer {
            visibility: visible;
          }

.rdp .rdp-months.invalid-end-date .rdp-months .rdp-caption_end .date-picker-footer {
            visibility: visible;
          }
