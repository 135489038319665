
  .app-page .app-debug-drawer {
    display: flex;
    flex-flow: column;
    z-index: 9
  }

.app-page .app-debug-drawer > .MuiPaper-root {
      width: var(--app-debug-width);
      height: 100vh;
      margin-top: 0em;

      transition-property: height, margin-top, width;
      transition-duration: var(--default-transition-duration);
      transition-timing-function: var(--default-transition-timing-function);
    }

.app-page .app-debug-drawer .app-debug-drawer-intro {
      text-align: center;
      font-weight: 100;
      font-size: 0.9em;
      padding: 0.4em 0.6em;
    }

.app-page .app-debug-drawer .app-debug-drawer-header {
      flex: 0 0 auto;
      display: grid;
      align-items: center;
      grid-template-columns: 6ch auto 6ch;
      font-size: 1.6em;
      line-height: 1.6em;
      padding: 0 0.3em;
      height: 2.5em;
      font-weight: 100;
      border-left: 1px solid #f8f8f8;

      background: #096da9;
      color: #f8f8f8
    }

.app-page .app-debug-drawer .app-debug-drawer-header .app-debug-drawer-header-section button {
          padding: 0em 0.25em 0.1em 0.25em;
          font-size: 1em;
          font-weight: bold;
          cursor: pointer;
          color: #ffffff
        }

.app-page .app-debug-drawer .app-debug-drawer-header .app-debug-drawer-header-section button:hover {
            text-shadow: 0 0 2px #ffffff;
          }

.app-page .app-debug-drawer .app-debug-drawer-header .app-debug-drawer-header-center {
        text-align: center;
      }

.app-page .app-debug-drawer .app-debug-drawer-header .app-debug-drawer-header-right {
        text-align: right;
      }

.app-page .app-debug-drawer .app-debug-drawer-header .app-debug-drawer-bulk-window-control {
        border: 1px solid #ffffff66;
        padding: 0em 0.25em 0.1em 0.25em;
        font-size: 1em;
        line-height: 1em;
        font-weight: bold;
        border-radius: 0.35em;
        cursor: pointer;
        color: #ffffff
      }

.app-page .app-debug-drawer .app-debug-drawer-header .app-debug-drawer-bulk-window-control:hover {
          text-shadow: 0 0 1px #ffffff;
          box-shadow: 0 0 2px #ffffff;
        }

.app-page .app-debug-drawer .app-debug-drawer-middle {
      border-left: 1px solid #096da9;
      flex: 1 1 auto;
      color: #383838;
      overflow: auto
    }

.app-page .app-debug-drawer .app-debug-drawer-middle .app-debug-drawer-middle-section {
        margin: 0;
        border-bottom: 0.1em solid #096da966
      }

.app-page .app-debug-drawer .app-debug-drawer-middle .app-debug-drawer-middle-section .app-debug-drawer-section-title {
          text-align: center;
          font-size: 1.15em;
          font-weight: 600;
          color: #096da9;
          background: linear-gradient(to top, transparent 0%, #096da916 100%);
          display: grid;
          grid-template-columns: 1fr 3fr 1fr;
          align-items: center;
          justify-content: space-between;
          padding: 0.2em 0 0.2em 0;
          border-bottom: 0.2em dotted #096da966;

          transition-property: border, padding;
          transition-duration: 0.3s;
          transition-timing-function: ease-in
        }

.app-page .app-debug-drawer .app-debug-drawer-middle .app-debug-drawer-middle-section .app-debug-drawer-section-title .app-debug-drawer-section-title-left {
            color: #444444;
            font-size: 0.8rem;
          }

.app-page .app-debug-drawer .app-debug-drawer-middle .app-debug-drawer-middle-section .app-debug-drawer-section-title .app-debug-drawer-section-title-text {
            cursor: zoom-out;
          }

.app-page .app-debug-drawer .app-debug-drawer-middle .app-debug-drawer-middle-section .app-debug-drawer-section-title .app-debug-drawer-section-title-right {
            text-align: right;
            padding: 0 0.2em;
          }

.app-page .app-debug-drawer .app-debug-drawer-middle .app-debug-drawer-middle-section .app-debug-app-status-control-list {
          margin: 0;
        }

.app-page .app-debug-drawer .app-debug-drawer-middle .app-debug-drawer-middle-section .app-debug-drawer-section-window-control {
          border: 1px solid #096da966;
          padding: 0em 0.25em 0.1em 0.25em;
          font-size: 1em;
          line-height: 1em;
          font-weight: bold;
          border-radius: 0.35em;
          background-color: #f8f8f8;
          cursor: pointer
        }

.app-page .app-debug-drawer .app-debug-drawer-middle .app-debug-drawer-middle-section .app-debug-drawer-section-window-control:hover {
            text-shadow: 0 0 1px #096da9;
            box-shadow: 0 0 2px #096da9;
          }

.app-page .app-debug-drawer .app-debug-drawer-middle .app-debug-drawer-middle-section.app-debug-drawer-middle-section-minimized {
          border-bottom: none
        }

.app-page .app-debug-drawer .app-debug-drawer-middle .app-debug-drawer-middle-section.app-debug-drawer-middle-section-minimized .app-debug-drawer-section-title {
            border-bottom: 0.1em dotted #096da966;
            padding: 0.2em 0 0.3em 0
          }

.app-page .app-debug-drawer .app-debug-drawer-middle .app-debug-drawer-middle-section.app-debug-drawer-middle-section-minimized .app-debug-drawer-section-title .app-debug-drawer-section-title-text {
              cursor: zoom-in;
            }

.app-page .app-debug-drawer .app-debug-drawer-middle .app-debug-drawer-user-info {
        font-size: 0.9em;
        padding: 0.1em 0.3em;
        margin-bottom: 0.5em;
      }

.app-page .app-debug-drawer .app-debug-drawer-middle .app-debug-drawer-button-group {
        /* display: flex;
        justify-content: center; */
        margin: 0.4em 0.6em;
        width: calc(100% - 1.2em);
      }

.app-page .app-debug-drawer .app-debug-drawer-middle .app-debug-drawer-button-row {
        margin: 0.5em 0;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1em;
      }

.app-page .app-debug-drawer .app-debug-drawer-subsection {
      border-bottom: 1px solid #cccccc
    }

.app-page .app-debug-drawer .app-debug-drawer-subsection .app-debug-drawer-subsection-title {
        background: linear-gradient(to bottom, #f8f8f8 0%, #eee 0.125em, #f9f9f9 100%);
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 0.2em;
        padding: 0.2em 0.5em 0.3em 0.5em;
        border-bottom: 1px solid #dddddd;
        margin-bottom: 0.25em
      }

.app-page .app-debug-drawer .app-debug-drawer-subsection .app-debug-drawer-subsection-title .app-debug-drawer-subsection-title-icon {
          font-size: 1.25em;
          color: #666;
        }

.app-page .app-debug-drawer .app-debug-drawer-subsection .app-debug-drawer-subsection-title .app-debug-drawer-subsection-title-text {
          flex: 1 1 auto;
          color: #2878a888;
          text-align: right;
          font-weight: 100;
          text-shadow: 0 0 0.0625em #2878a8;
          font-size: 0.95em;
          padding: 0.2em 0;
        }

.app-page .app-debug-drawer .app-debug-drawer-subsection .app-debug-drawer-subsection-form-row-group {
        display: flex;
        flex-flow: column;
        gap: 0.3em;
        padding: 0 0.3em
      }

.app-page .app-debug-drawer .app-debug-drawer-subsection .app-debug-drawer-subsection-form-row-group .app-debug-drawer-subsection-row .app-debug-html-label {
            display: grid;
            grid-template-columns: 8ch auto;
            align-items: center;
            grid-gap: 0.6em
          }

.app-page .app-debug-drawer .app-debug-drawer-subsection .app-debug-drawer-subsection-form-row-group .app-debug-drawer-subsection-row .app-debug-html-label > span {
              text-align: right;
            }

.app-page .app-debug-drawer .app-debug-drawer-subsection .app-debug-drawer-subsection-form-row-group .app-debug-drawer-subsection-row .app-debug-html-label.app-debug-checkbox-label {
              cursor: pointer
              /* grid-template-columns: 15ch auto;
              justify-content: space-between; */
            }

.app-page .app-debug-drawer .app-debug-drawer-subsection .app-debug-drawer-subsection-form-row-group .app-debug-drawer-subsection-row .app-debug-html-label.app-debug-checkbox-label input {
                justify-self: flex-start;
              }

.app-page .app-debug-drawer .app-debug-drawer-subsection .app-debug-drawer-subsection-form-row-group .app-debug-drawer-subsection-checkbox-group {
          display: flex;
          align-items: center;
          gap: 0.25em
          /* justify-content: space-between; */
        }

.app-page .app-debug-drawer .app-debug-drawer-subsection .app-debug-drawer-subsection-form-row-group .app-debug-drawer-subsection-checkbox-group .app-debug-checkbox-label {
            /* border: 1px solid #DDDDDD; */
            flex: 1 1 auto;
            display: grid;
            grid-template-columns: 2ch auto;
            align-items: center;
            justify-content: flex-start;
            transition: background 0.3s ease-in-out
          }

.app-page .app-debug-drawer .app-debug-drawer-subsection .app-debug-drawer-subsection-form-row-group .app-debug-drawer-subsection-checkbox-group .app-debug-checkbox-label:hover {
              background: #096da922;
              cursor: pointer;
            }

.app-page .app-debug-drawer .app-debug-drawer-subsection.app-api-debug-notifications .app-debug-drawer-subsection-form-row-group {
          margin: 0.2em 0.6em;
        }

.app-page .app-debug-drawer .app-debug-drawer-subsection.app-api-debug-notifications .app-api-notification-body-type-row {
          justify-content: center;
        }

.app-page .app-debug-drawer .app-debug-drawer-subsection.app-api-debug-notifications .app-api-debug-checkboxes {
          display: inline-grid;
          margin: 0 auto;
          grid-template-columns: 1fr 1fr;
          /* align-items: flex-start; */
          /* justify-content: center; */
          grid-gap: 1.4em;
        }

.app-page .app-debug-drawer .app-debug-drawer-footer {
      flex: 0 0 auto;
      padding: 0 0.5em;
      border-left: 1px solid #096da9;
      border-top: 2px solid #096da9
    }

.app-page .app-debug-drawer .app-debug-drawer-footer .app-debug-window-id {
        text-align: center
      }

.app-page .app-debug-drawer .app-debug-drawer-footer .app-debug-window-id > div {
          overflow: auto;
          word-break: keep-all;
          white-space: nowrap;
        }

.app-page .app-debug-drawer .app-debug-drawer-footer .app-debug-path-chunks {
        font-size: 0.9em;
        text-align: center
      }

.app-page .app-debug-drawer .app-debug-drawer-footer .app-debug-path-chunks .app-debug-path-chunk:after {
            display: inline-block;
            content: '/';
            padding: 0 0.2em;
            color: #096da9;
            font-weight: 900;
          }

.app-page .app-debug-drawer .app-debug-drawer-footer .app-debug-path-chunks .app-debug-path-chunk:last-of-type:after {
              display: none;
            }

.app-page .app-debug-drawer .app-status-toggle {
      list-style: '✗';
      padding: 0.2em 0 0.2em 0.5em;
      line-height: 1.6em;

      color: #aa0000
    }

.app-page .app-debug-drawer .app-status-toggle .app-status-toggle-link {
        cursor: pointer;
        color: #333333
      }

.app-page .app-debug-drawer .app-status-toggle .app-status-toggle-link:hover {
          color: #000000;
          text-shadow: 0 0 1px #33333399;
        }

.app-page .app-debug-drawer .app-status-toggle:hover {
        background: linear-gradient(to right, transparent 0%, #eeeeee 100%);
      }

.app-page .app-debug-drawer .app-status-toggle.app-status-toggle-active {
        list-style: '✓';
        color: #00aa00;
      }

.app-page.has-app-header .app-debug-drawer > .MuiPaper-root {
        box-shadow: -1px 0px 1px #33333366;
      }

.app-page.has-app-debug .modal-dialog-wrapper {
      right: var(--app-debug-width);
    }

.app-debug-section-unavailable {
  text-align: center;
}

.app-debug-api-section-root {
  padding: 0em 0.8em
}

.app-debug-api-section-root .app-api-debug-row {
    margin: 0.8em 0
  }

.app-debug-api-section-root .app-api-debug-row.app-api-debug-row-common-values,
    .app-debug-api-section-root .app-api-debug-row.app-api-debug-dropdowns {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 0.4em;
    }

.app-debug-api-section-root .app-api-debug-row-modal-button {
    margin: 0.8em;
    text-align: right;
  }

.app-debug-api-section-root .app-api-debug-row-request-paste-url {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: stretch;
    grid-gap: 0.4em;
  }

.app-debug-api-section-root .app-api-debug-row-request-data .MuiInputBase-inputMultiline {
      font-family: monospace, monospace;
      font-size: 0.8em;
    }

.app-debug-api-section-root .app-api-debug-row-options {
    display: grid;
    grid-template-columns: repeat(2, auto);
    grid-gap: 2em;
    justify-content: center;
    align-items: center
    /* padding: 0em 0.5em; */
  }

.app-debug-api-section-root .app-api-debug-row-options .api-debug-option-checkbox {
      padding: 0.2em 0.5em;
      margin: 0
    }

.app-debug-api-section-root .app-api-debug-row-options .api-debug-option-checkbox:hover {
        background: #096da933;
      }

.app-debug-api-section-root .app-api-debug-row-options .api-debug-option-checkbox .MuiCheckbox-root {
        padding: 0 0.2em;
      }

.app-debug-api-section-root .api-debug-action-buttons {
    margin-left: auto;
    display: flex;
    align-items: center;
    gap: 0.5em
  }

.app-debug-api-section-root .api-debug-action-buttons .spinner-wrapper {
      height: 1.6em
    }

.app-debug-api-section-root .api-debug-action-buttons .spinner-wrapper svg {
        height: inherit;
      }

.app-debug-api-section-root .app-api-debug-row-common-params .app-api-debug-row-common-params-toggle {
    }

.app-debug-api-section-root .app-api-debug-row-common-params .common-param-grid-wrapper {
      background: #096da908;
      margin-bottom: 0.2em;
    }

.app-debug-api-section-root .app-api-debug-row-common-params .common-param-grid {
      display: grid;
      /* grid-gap: 0.4em 0.6em; */
      grid-template-columns: repeat(2, auto) 2em;
      align-items: stretch
      /* border-bottom: 1px solid #096da999; */
    }

.app-debug-api-section-root .app-api-debug-row-common-params .common-param-grid .common-param-grid-cell {
        padding: 0.2em 0.3em;
        border-style: solid;
        border-width: 1px;
        border-bottom-width: 0;
        border-right-width: 0;
        border-color: #096da999;
        display: flex;
        align-items: center
      }

.app-debug-api-section-root .app-api-debug-row-common-params .common-param-grid .common-param-grid-cell:nth-child(3n + 1) {
          text-align: right;
        }

.app-debug-api-section-root .app-api-debug-row-common-params .common-param-grid .common-param-grid-cell:nth-child(3n + 2) {
          border-right-width: 1px;
        }

.app-debug-api-section-root .app-api-debug-row-common-params .common-param-grid .common-param-grid-cell:nth-child(3n + 3) {
          border-left-width: 0px;
          border-right-width: 1px;
        }

.app-debug-api-section-root .app-api-debug-row-common-params .common-param-grid .common-param-grid-cell:last-of-type {
          border-bottom-width: 1px;
        }

.app-debug-api-section-root .app-api-debug-row-common-params .common-param-grid .common-param-grid-cell.common-param-grid-cell-actions {
          padding: 0.2em 0;
          justify-content: center
        }

.app-debug-api-section-root .app-api-debug-row-common-params .common-param-grid .common-param-grid-cell.common-param-grid-cell-actions path {
            fill: #096da9;
          }

.app-debug-api-section-root .app-api-debug-row-common-params .common-param-grid .common-param-grid-cell.common-param-grid-cell-actions:hover {
            background-color: #5589b6aa
          }

.app-debug-api-section-root .app-api-debug-row-common-params .common-param-grid .common-param-grid-cell.common-param-grid-cell-actions:hover path {
              fill: #ffffff;
            }

.app-debug-api-section-root .app-api-debug-row-common-params .common-param-grid .common-param-grid-cell.common-param-grid-cell-name {
          justify-content: flex-end;
        }

.app-debug-api-section-root .app-api-debug-row-common-params .common-param-grid .common-param-grid-cell.common-param-grid-cell-value {
          word-break: break-all;
        }

.app-debug-api-section-root .app-api-debug-row-common-params .common-param-grid .common-param-grid-cell-header {
        padding: 0.2em 0.3em;
        border-style: solid;
        border-width: 1px;
        border-bottom-width: 0;
        border-right-width: 0;
        background-color: #5589b6;
        border-color: #096da9;
        font-weight: 600;
        color: #fff
      }

.app-debug-api-section-root .app-api-debug-row-common-params .common-param-grid .common-param-grid-cell-header:nth-child(3n + 1) {
          text-align: right;
        }

.app-debug-api-section-root .app-api-debug-row-common-params .common-param-grid .common-param-grid-cell-header:nth-child(3n + 2) {
          border-left-color: #fff;
        }

.app-debug-api-section-root .app-api-debug-row-common-params .common-param-grid .common-param-grid-cell-header:nth-child(3n + 3) {
          padding: 0.2em 0;
          text-align: center;
          border-left-color: #5589b6
        }

.app-debug-api-section-root .app-api-debug-row-common-params .common-param-grid .common-param-grid-cell-header:nth-child(3n + 3) path {
            fill: #fff;
          }
.api-endpoint-option-wrapper {
  width: 100%;
  display: grid;
  grid-template-columns: auto 1.4rem;
  grid-gap: 0.4em;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px dotted #ccc;
  padding: 0.2em 0
}
.api-endpoint-option-wrapper .api-endpoint-option-name {
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: keep-all;
  }
.api-endpoint-option-wrapper .api-endpoint-option-flags {
    display: flex;
    flex-flow: column;
    gap: 0.2em;
    justify-content: center;
    align-items: center;
    font-family: monospace, monospace;
    grid-row: span 2
  }
.api-endpoint-option-wrapper .api-endpoint-option-flags .api-endpoint-option-flag {
      width: 1rem;
      height: 1rem;
      font-size: 0.8em;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #555;
      color: #eee;
    }
.api-endpoint-option-wrapper .api-endpoint-option-flags {

    /* & > span {
      &:after {
        content: ' | ';
        display: inline-block;
      }
      &:last-of-type {
        &:after {
          content: '';
          display: none;
        }
      }
    } */
  }
.api-endpoint-option-wrapper .api-endpoint-option-info {
    /* grid-column: span 2; */
    color: #666666;
  }
.api-endpoint-option-wrapper {

  /* &.api-endpoint-type-get {
    & .api-endpoint-option-info {
      color: #F00;
    }
  }
  &.api-endpoint-type-post {
    & .api-endpoint-option-info {
      color: #FF0;
    }
  } */
}

.app-debug-test-modal .modal-dialog-contents {
    display: flex;
    flex-flow: column;
  }

.app-debug-test-modal .modal-dialog-title {
    flex: 1 0 auto;
    text-align: center;
    color: #096da9;
  }

.app-debug-test-modal .modal-dialog-body .app-debug-user-modal-contents {
      width: 50vw;
      height: 70vh
    }

.app-debug-test-modal .modal-dialog-body .app-debug-user-modal-contents .app-debug-user-data-field {
        border: 1px solid #096da9;
        background: linear-gradient(to bottom, #096da922 0%, #096da906 2em, #096da901 100%);
        width: calc(100% - 2px);
        font-family: monospace;
        font-size: 1.2em;
        line-height: 1.6em;
        display: block;
        height: 100%;
      }

.app-debug-test-modal .modal-dialog-body .app-debug-user-modal-contents .app-debug-user-modal-output {
        max-width: 50vw;
        font-family: monospace;
        font-size: 1.2em;
        line-height: 1.6em;
        display: block;
        white-space: pre;
      }

.app-debug-test-modal .modal-dialog-footer {
    flex: 0 0 2em;
    display: flex;
    flex-flow: column
  }

.app-debug-test-modal .modal-dialog-footer .app-debug-modal-footer {
      padding: 0.3em 0;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 0.5em;
    }

.modal-messages {
  font-size: 0.8em;
}

.app-debug-header-toolbar {
  width: 100%;
  color: #ffffff;
  display: flex;
  align-items: center;
  height: inherit
}

.app-debug-header-toolbar .app-header-toolbar-title {
    display: flex;
    align-items: center;
    margin: 0 0.6em;
    font-size: 1.2em;
    font-weight: 100;
  }

.app-debug-header-toolbar .app-debug-header-toolbar-section {
    display: flex;
    align-items: center;
    gap: 0.8em;
    margin: 0.3em 0.5em;
  }

.app-debug-active-notifications .app-debug-active-notifications-title {
    padding: 0.2em 0.4em;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

.app-debug-active-notifications .app-debug-active-notifications-list {
    display: block;
    overflow: auto;
    list-style: none;
    padding: 0.25em;
    border: 1px solid #88888888;
    margin: 0.25em;
    /* width: 100%; */
    height: 6em
  }

.app-debug-active-notifications .app-debug-active-notifications-list .app-debug-active-notification-item {
      margin: 0.125em 0.25em;
      border-bottom: 1px solid #cccccc88;
      display: flex;
      align-items: center;
      gap: 0.3em
    }

.app-debug-active-notifications .app-debug-active-notifications-list .app-debug-active-notification-item .app-notification-index {
        flex: 0 1 auto;
      }

.app-debug-active-notifications .app-debug-active-notifications-list .app-debug-active-notification-item .app-notification-uuid {
        flex: 1 1 auto;
      }

.app-debug-active-notifications .app-debug-active-notifications-list .app-debug-active-notification-item .app-notification-flags {
        flex: 0 0 auto;
        display: flex;
        gap: 0.25em;
        align-items: center;
        padding: 0 0.25em;
        justify-content: space-evenly
      }

.app-debug-active-notifications .app-debug-active-notifications-list .app-debug-active-notification-item .app-notification-flags .app-notification-flag {
          color: #eeeeee
        }

.app-debug-active-notifications .app-debug-active-notifications-list .app-debug-active-notification-item .app-notification-flags .app-notification-flag.app-notification-flag-active {
            color: #096da9;
            opacity: 1;
          }

.app-debug-active-notifications .app-debug-active-notifications-list .app-debug-active-notification-item.notification-item-warning .app-notification-index {
          color: #f80;
        }

.app-debug-active-notifications .app-debug-active-notifications-list .app-debug-active-notification-item.notification-item-info .app-notification-index {
          color: #46b282;
        }

.app-debug-active-notifications .app-debug-active-notifications-list .app-debug-active-notification-item.notification-item-error .app-notification-index {
          color: #b12e2e;
        }
.app-notification-info-tooltip {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 0.5em;
  align-items: center
}
.app-notification-info-tooltip > *:nth-child(2n + 1) {
    font-weight: 600;
    justify-self: flex-end;
  }
.app-notification-info-tooltip > *:nth-child(2n) {
    font-size: 1.1em;
  }

.app-api-debug-modal .modal-dialog-contents {
    width: calc(90vw - var(--app-debug-width));
    max-height: 80vh
  }

.app-api-debug-modal .modal-dialog-contents .app-debug-api-section-root {
      overflow: auto;
      padding-top: 0.8em;
      height: inherit;
    }

.app-api-debug-modal .modal-dialog-contents .app-api-debug-response-wrapper {
      display: flex;
      flex-flow: column;
      overflow: hidden;
      height: inherit;
      justify-content: space-between
    }

.app-api-debug-modal .modal-dialog-contents .app-api-debug-response-wrapper .app-api-debug-response-data {
        flex: 0 1 60vh;
        overflow: auto;
      }

.app-api-debug-modal .modal-dialog-contents .app-api-debug-response-wrapper .app-api-debug-response-footer {
        flex: 0 0 auto;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 0.6em;
      }

.app-ui-debug-options {
  /* display: inline-flex; */
  gap: 1ch;
  justify-content: center;
}
