.test-assignment-list-wrapper {
  padding: 0.75em
  /* 
  .panel {
    background-color: #fff;
    border: 1px solid #dee5eb;
    border-radius: 0.25em;
    padding: 0.75em 1em;
    box-sizing: border-box;
  } */
}
.test-assignment-list-wrapper .test-assignment-actions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center
  }
.test-assignment-list-wrapper .test-assignment-actions .test-assignment-buttons {
      display: flex;
      align-items: center;
      text-decoration: none;
      gap: 1.3em
    }
.test-assignment-list-wrapper .test-assignment-actions .test-assignment-buttons .create-new-assignment-button-link {
        text-decoration: none;
      }
.test-assignment-list-wrapper .test-assignment-actions .test-assignment-buttons .toggle-wrapper {
        display: flex;
        gap: 0.1em
      }
.test-assignment-list-wrapper .test-assignment-actions .test-assignment-buttons .toggle-wrapper .test-assignment-toggle {
          height: 2.75em;
        }
.test-assignment-list-wrapper .test-assignment-actions .test-assignment-buttons .toggle-wrapper .left-toggle {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
.test-assignment-list-wrapper .test-assignment-actions .test-assignment-buttons .toggle-wrapper .right-toggle {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
.test-assignment-list-wrapper .test-assignment-actions .test-assignment-buttons .toggle-wrapper .untoggled {
          background-color: white;
        }
.test-assignment-list-wrapper .dropdown-wrapper {
    margin: 1rem 0
  }
.test-assignment-list-wrapper .dropdown-wrapper .dropdown-container-custom {
      max-width: 18rem;
      z-index: 2
    }
.test-assignment-list-wrapper .dropdown-wrapper .dropdown-container-custom .dropdown-header-custom {
        min-width: 18rem;
      }
.test-assignment-list-wrapper .dropdown-wrapper .spinner-wrapper {
      display: flex;
      /*margin-top: 0.4em;
      margin-right: 2em;*/
      height: 1.6em;
      width: 1.6em;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%
    }
.test-assignment-list-wrapper .dropdown-wrapper .spinner-wrapper .loader-spinner {
        height: 1.6em;
        /*margin-top: -0.6em;
        margin-right: -2.6em;*/
        position: absolute;
        left: 50%;
        margin: -0.5em 0 0 -2em
      }
.test-assignment-list-wrapper .dropdown-wrapper .spinner-wrapper .loader-spinner path {
          fill: var(--link-color);
        }
.test-assignment-list-wrapper .dropdown-wrapper .spinner-wrapper .dropdown-warning-icon {
        height: 1.6em;
        margin-top: -0.7em;
        margin-right: -0.4em
      }
.test-assignment-list-wrapper .dropdown-wrapper .spinner-wrapper .dropdown-warning-icon path {
          fill: var(--input-color-invalid);
        }
.test-assignment-list-wrapper .assignments-table-wrapper {
    overflow: hidden
  }
.test-assignment-list-wrapper .assignments-table-wrapper .table-loader-box-mask {
      z-index: 3;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ffffffcc;
      box-shadow: 0 0 1px #333333cc;

      transition-property: top, background-color;
      transition-timing-function: ease-in-out;
      transition-duration: 0.5s;
    }
.test-assignment-list-wrapper .assignments-table-wrapper .table-loader-box {
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: center;
      gap: 1em;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: calc(100vh - 4em);
      z-index: 3;

      transition-property: top, height;
      transition-timing-function: ease-in-out;
      transition-duration: 0.5s
    }
.test-assignment-list-wrapper .assignments-table-wrapper .table-loader-box .table-loader-title {
        font-weight: 300;
        font-size: 2em;
        color: var(--app-background-color-inverse);
        margin: 1em 0 0 0;
      }
.test-assignment-list-wrapper .assignments-table-wrapper .table-loader-box .table-loader-message {
        font-weight: 400;
        font-size: 1.4em;
        margin: 1em 0 1em 0;
        text-align: center;
      }
.test-assignment-list-wrapper.table-ready .assignments-table-wrapper .table-loader-box-mask {
        top: 10em;
        background-color: #ffffff99;
      }
.test-assignment-list-wrapper.table-ready .assignments-table-wrapper .table-loader-box {
        top: 11em;
        height: calc(100vh - 14em);
      }
