
    .app-layout-root header.app-header .app-header-bar {
      /* for some reason this 1px causes horizontal scrollbar */
      max-width: calc(100% - 1px);
    }
.app-layout-root header.app-header .app-header-title-section {
      line-height: initial;
    }
@media only screen and (max-width: 993px) {
      .app-layout-root header.app-header .app-header-district-name {
        display: none;
      }
    }
.app-layout-root header.app-header .header-nav-icon-links.assignment + .app-header-title-section {
      border-left: none;
    }
