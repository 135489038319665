
  .test-assignment-list-table > .MuiTableContainer-root {
    height: auto !important;
  }
.test-assignment-list-table {

  /* font-size: 0.9em; */
}
.test-assignment-list-table .elevate-table > tbody .elevate-tr {
        vertical-align: top
      }
.test-assignment-list-table .elevate-table > tbody .elevate-tr .elevate-td {
          padding: 0.35em 0.5em
        }
.test-assignment-list-table .elevate-table > tbody .elevate-tr .elevate-td .grade-domain-items {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            gap: 0.25em
          }
.test-assignment-list-table .elevate-table > tbody .elevate-tr .elevate-td .grade-domain-items .grade-domain-item {
              color: #000000cc;
              padding: 0.125em 0.3em;
              background: #ebf0f3;
              box-shadow: -1px 0 0 #d8e1e880;
              min-width: 2ch;
              text-align: center;
            }
.test-assignment-list-table .elevate-table .column-assignmenttype {
      width: 20%;
    }
.test-assignment-list-table .elevate-table .column-assignmentname {
      width: calc(30% - 10ch);
    }
.test-assignment-list-table .elevate-table .column-gradedomains {
      width: 10%;
    }
.test-assignment-list-table .elevate-table .column-creator {
      width: 15%;
    }
.test-assignment-list-table .elevate-table .column-lastUpdated {
      width: 10%;
    }
.test-assignment-list-table .elevate-table .column-dates {
      width: 10%;
    }
.test-assignment-list-table .elevate-table .column-status {
      width: 10%;
    }
.test-assignment-list-table .elevate-table .column-actions {
      width: 10ch;
    }
.test-assignment-list-table .assignment-actions {
    display: flex;
    justify-content: flex-end
  }
.test-assignment-list-table .assignment-actions .assignment-action {
      padding: 0.125em;
      display: flex;
      align-items: center
    }
.test-assignment-list-table .assignment-actions .assignment-action svg {
        height: 1rem;
        width: 1rem;
      }
.test-assignment-list-table .assignment-actions .assignment-action.disabled {
        /*pointer-events: none;*/
      }
.test-assignment-list-table .assignment-actions .assignment-action.disabled button {
          cursor: default;
        }
.test-assignment-list-table .assignment-actions .assignment-action.disabled svg path {
            fill: #aaa;
          }
.test-assignment-list-table .assignment-actions .assignment-action-button {
      background-color: transparent
    }
.test-assignment-list-table .assignment-actions .assignment-action-button svg {
        height: 1rem;
        width: 1rem;
        fill: var(--app-button-background-color);
      }
.test-assignment-list-table .assignment-status-value.status-open {
      color: #46b282;
    }
.test-assignment-list-table .assignment-status-value.status-scheduled {
      color: #808080;
    }
.test-assignment-list-table .assignment-status-value.status-closed {
      color: #b12e2e;
    }
.test-assignment-list-table .assignment-table-item-name-link {
    color: var(--link-color);
    text-decoration: none;
    font-weight: 300
  }
.test-assignment-list-table .assignment-table-item-name-link.disabled-link {
      pointer-events: none;
      color: var(--text-color-default);
    }
.test-assignment-list-table .assignment-table-item-name-link .assignment-table-item-name-arrow {
      width: 0;
      opacity: 0;
      margin-left: -1em;
      overflow: hidden;
      transition-property: width, opacity, margin;
      transition-duration: 0.3s;
      transition-timing-function: ease-in-out;
    }
.test-assignment-list-table .assignment-table-item-name-link:hover .assignment-table-item-name-arrow {
        width: 1.6em;
        opacity: 1;
        margin-left: 0.2em;
      }
.test-assignment-list-table .dropdown-header-custom {
    display: none;
  }
.test-assignment-list-table .elevate-table-header-cell-filters-wrapper:has(.tooltip-custom) {
      display: none;
    }
.test-assignment-list-table .dropdown-container-custom .dropdown-list-container-custom ul {
        max-height: 18lh;
      }
.test-assignment-list-table .dropdown-container-custom.tooltip-custom {
      display: none;
    }
.test-assignment-list-table .tooltiptext {
    margin-left: 8px;
    margin-bottom: 28px;
    text-align: left;
  }
.test-assignment-list-table svg {
    opacity: 1
  }
.test-assignment-list-table svg .a {
      fill: #6a6d6d;
      opacity: 1;
    }
.elevate-table-header-cell-filters-wrapper .dropdown-header-custom.expanded {
    display: none;
  }

.delete-assignment-modal .modal-dialog-body .delete-assignment-message {
      display: flex;
      align-items: center;
      justify-content: center;
    }

.delete-assignment-modal .button-wrapper {
    display: flex;
    justify-content: space-evenly
  }

.delete-assignment-modal .button-wrapper .MuiButtonBase-root {
      text-transform: none;
      font-weight: 400;
    }
