
  .app-debug-logging-section-root .app-debug-drawer-subsection-body {
    padding: 0.25em 0.6em;
  }

.app-debug-logging-section-root .log-filter-add .MuiFormGroup-row {
      justify-content: space-evenly;
      /* align-items: center; */
    }

.app-debug-logging-section-root .exclude-log-form-group {
    padding: 0.2em 0.4em;
    align-items: center
  }

.app-debug-logging-section-root .exclude-log-form-group > .MuiFormControl-root {
      flex: 1 0 auto;
    }

.app-debug-logging-section-root .exclude-log-form-group div:last-of-type {
      flex: 0 1 auto;
    }

.app-debug-logging-section-root .levels-log-form-group {
    justify-content: center;
  }

.app-debug-logging-section-root .log-filter-namespaces-wrapper .log-filter-namespaces-title {
      text-align: center;
      margin: 0.4em 0;
      font-weight: 100;
      color: var(--link-color);
    }

.app-debug-logging-section-root .log-filter-namespaces-wrapper .log-filter-namespaces {
      border: 1px solid #cccccc;
      padding: 0;
      margin: 0
    }

.app-debug-logging-section-root .log-filter-namespaces-wrapper .log-filter-namespaces .log-filter-namespace-item {
        display: grid;
        grid-template-columns: auto auto;
        padding: 0.1em 0.8em;
        margin: 0.1em 0.2em;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #cccccc
      }

.app-debug-logging-section-root .log-filter-namespaces-wrapper .log-filter-namespaces .log-filter-namespace-item:last-child {
          border-bottom: none;
        }

.app-debug-logging-section-root .exclude-log-tooltip-icon {
    color: var(--link-color);
  }

.app-debug-logging-section-root .log-debug-reset-button-wrapper {
    text-align: center;
    margin: 0.3em 0 0.6em 0;
  }

.exclude-log-tooltip-text {
  font-size: 1.2em;
}
