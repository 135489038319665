.elevate-privacy {
  font-weight: 300;
  padding: 0.3em 0.6em
}
.elevate-privacy h2,
  .elevate-privacy h3 {
    text-align: center;
    color: var(--link-color);
  }
.elevate-privacy h3 {
    margin-top: 1.2em;
    color: var(--link-color);
  }
.elevate-privacy h4,
  .elevate-privacy h5 {
    padding-left: 4em;
    font-size: 1em;
    font-weight: 600;
    margin-bottom: 0.4em;
    color: var(--link-color);
  }
.elevate-privacy h5 {
    padding-left: 3em;
  }
.elevate-privacy .privacy-toc-wrapper {
    font-size: 1.2em
  }
.elevate-privacy .privacy-toc-wrapper .privacy-toc-title {
      color: var(--link-color);
    }
.elevate-privacy .privacy-toc-wrapper .toc-item {
      font-size: 0.8em;
      cursor: pointer;
    }
.elevate-privacy .article-title {
    color: var(--link-color);
    font-size: 3em;
    font-weight: 400;
  }
.elevate-privacy .last-content-update {
    text-align: center;
    font-weight: 700;
  }
.elevate-privacy .terms-definitions {
    list-style: none;
    margin: 1em 1em 2em 0;
    text-align: left
  }
.elevate-privacy .terms-definitions li {
      display: grid;
      grid-template-columns: 25ch auto;
      grid-column-gap: 0.5em;
      align-items: flex-start;
      padding: 0.3em 0
    }
.elevate-privacy .terms-definitions li strong {
        text-align: right;
      }
.elevate-privacy p {
    display: block;
    text-align: justify;
  }
.elevate-privacy a {
    color: var(--link-color);
  }
