
    .app-debug-section-session .idtoken-action-group .idtoken-action-row {
      padding: 0.2em 0.8em;
      display: flex;
      gap: 0.4em;
      align-items: center;
      justify-content: space-evenly;
    }
.app-debug-section-session .idtoken-expire-ticker {
    /* padding: 0.4em 0.8em; */
  }
.app-debug-section-session .idtoken-expire-ticker .idtoken-expire-ticker-text {
      padding: 0.4em 0.8em;
      text-align: center;
      padding-bottom: 0.2em;
      margin-bottom: 0.2em
    }
.app-debug-section-session .idtoken-expire-ticker .idtoken-expire-ticker-text > span {
        font-weight: 600;
      }
.app-debug-section-session .idtoken-expire-ticker .idtoken-expire-ticker-text.idtoken-expired-text {
        color: #992222;
      }
.app-debug-section-session .idtoken-expire-ticker .idtoken-actions {
      display: grid;
      padding: 0.2em 0.8em;
      grid-template-columns: auto auto auto;
      grid-column-gap: 0.2em;
      align-items: center;
      justify-content: center;
    }
.app-debug-section-session .session-info-block {
    text-align: center;
    font-weight: 100
  }
.app-debug-section-session .session-info-block .session-main-info {
      display: inline-grid;
      grid-template-columns: auto auto;
      grid-column-gap: 1ch;
      align-items: center;
      justify-content: flex-end
    }
.app-debug-section-session .session-info-block .session-main-info :nth-child(2n + 1) {
        text-align: right
      }
.app-debug-section-session .session-info-block .session-main-info :nth-child(2n + 1).token-invalid-label {
          color: #cc0000;
        }
.app-debug-section-session .session-info-block .session-main-info :nth-child(2n + 2) {
        font-weight: 300;
        text-align: left;
      }
.app-debug-section-session .idtoken-cookie-info {
    text-align: center;
  }
.app-debug-section-session .idtoken-cookie-info-sizes {
    margin-top: 0.6em;
    display: flex;
    gap: 0.125em;
    flex-flow: column;
    padding: 0 0.8em
  }
.app-debug-section-session .idtoken-cookie-info-sizes .idtoken-cookie-info-sizes-table {
      display: grid;
      grid-template-columns: auto auto;
      grid-column-gap: 0.35ch;
      align-items: center;
      justify-content: flex-end
    }
.app-debug-section-session .idtoken-cookie-info-sizes .idtoken-cookie-info-sizes-table .idtoken-cookie-info-sizes-cell {
        text-align: right;
        font-weight: 100
      }
.app-debug-section-session .idtoken-cookie-info-sizes .idtoken-cookie-info-sizes-table .idtoken-cookie-info-sizes-cell:nth-child(2n) {
          font-weight: 400;
        }
.app-debug-section-session .idtoken-cookie-info-sizes .idtoken-cookie-info-sizes-table .idtoken-cookie-info-sizes-cell.idtoken-cookie-info-sizes-cell-heading {
          font-weight: 300;
          padding-bottom: 0.1em
        }
.app-debug-section-session .idtoken-cookie-info-sizes .idtoken-cookie-info-sizes-table .idtoken-cookie-info-sizes-cell.idtoken-cookie-info-sizes-cell-heading:nth-child(2n) {
            text-align: center;
          }
.app-debug-section-session .app-debug-session-buttons-row {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5ch;
    padding: 0.1em 0.6em 0.3em 0.6em;
  }
.app-debug-section-session .session-info-grid-table {
    padding: 0.4em 0.6em;
    display: grid;
    grid-template-columns: auto auto;
    align-items: center
  }
.app-debug-section-session .session-info-grid-table .session-info-grid-cell {
      font-weight: 100;
      box-shadow: 0 0 0.0625em #33333333;
      padding-top: 0.1em;
      padding-bottom: 0.1em;
      padding-right: 0.5ch;
      text-align: right
    }
.app-debug-section-session .session-info-grid-table .session-info-grid-cell output {
        word-break: break-all;
      }
.app-debug-section-session .session-info-grid-table .session-info-grid-cell:nth-child(2n) {
        padding-right: 0;
        padding-left: 0.5ch;
        text-align: left;
        font-weight: 400;
      }
.app-debug-section-session .session-info-grid-table .session-info-grid-cell:nth-child(2n + 1) {
        font-size: 0.9em;
      }
.app-debug-section-session .session-info-grid-table .session-info-grid-cell.session-info-grid-cell-heading {
        font-size: 1em;
        font-weight: 100;
        text-align: left
      }
.app-debug-section-session .session-info-grid-table .session-info-grid-cell.session-info-grid-cell-heading:nth-child(2n + 1) {
          text-align: right;
        }
.app-debug-section-session .app-debug-session-ghost-input {
    border: none;
    background: none;
    color: var(--app-background-color-inverse);
  }
