.test-assignment-trash-table {
  /* font-size: 0.9em; */
}
.test-assignment-trash-table .elevate-table > tbody .elevate-tr {
        vertical-align: top
      }
.test-assignment-trash-table .elevate-table > tbody .elevate-tr .elevate-td {
          padding: 0.35em 0.5em
        }
.test-assignment-trash-table .elevate-table > tbody .elevate-tr .elevate-td .grade-domain-items {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            gap: 0.25em
          }
.test-assignment-trash-table .elevate-table > tbody .elevate-tr .elevate-td .grade-domain-items .grade-domain-item {
              color: #000000cc;
              padding: 0.125em 0.3em;
              background: #ebf0f3;
              box-shadow: -1px 0 0 #d8e1e880;
              min-width: 2ch;
              text-align: center;
            }
.test-assignment-trash-table .elevate-table .column-selected {
      width: 1%;
    }
.test-assignment-trash-table .elevate-table .column-assignmenttype {
      width: 20%;
    }
.test-assignment-trash-table .elevate-table .column-assignmentname {
      width: calc(30% - 10ch);
    }
.test-assignment-trash-table .elevate-table .column-gradedomains {
      width: 10%;
    }
.test-assignment-trash-table .elevate-table .column-creator {
      width: 14%;
    }
.test-assignment-trash-table .elevate-table .column-lastUpdated {
      width: 10%;
    }
.test-assignment-trash-table .elevate-table .column-dates {
      width: 10%;
    }
.test-assignment-trash-table .elevate-table .column-trash {
      width: 11%;
    }
.test-assignment-trash-table .elevate-table .column-status {
      width: 10%;
    }
.test-assignment-trash-table .elevate-table .column-actions {
      width: 10ch;
    }
.test-assignment-trash-table .assignment-actions {
    display: flex;
    justify-content: flex-end;
    color: #096da9;
    cursor: pointer;
  }
.test-assignment-trash-table .trash-table-checkbox {
    display: flex;
    justify-content: flex-end;
  }
.test-assignment-trash-table .assignment-status-value {
    color: black;
  }
.test-assignment-trash-table .assignment-table-item-name-link {
    color: var(--link-color);
    text-decoration: none;
    font-weight: 300
  }
.test-assignment-trash-table .assignment-table-item-name-link.disabled-link {
      pointer-events: none;
      color: var(--text-color-default);
    }
.test-assignment-trash-table .assignment-table-item-name-link .assignment-table-item-name-arrow {
      width: 0;
      opacity: 0;
      margin-left: -1em;
      overflow: hidden;
      transition-property: width, opacity, margin;
      transition-duration: 0.3s;
      transition-timing-function: ease-in-out;
    }
.test-assignment-trash-table .assignment-table-item-name-link:hover .assignment-table-item-name-arrow {
        width: 1.6em;
        opacity: 1;
        margin-left: 0.2em;
      }
.test-assignment-trash-table .dropdown-header-custom {
    display: none;
  }
.test-assignment-trash-table .elevate-table-header-cell-filters-wrapper:has(.tooltip-custom) {
      display: none;
    }
.test-assignment-trash-table .dropdown-container-custom .dropdown-list-container-custom ul {
        max-height: 18lh;
      }
.test-assignment-trash-table .dropdown-container-custom.tooltip-custom {
      display: none;
    }
.test-assignment-trash-table .tooltiptext {
    margin-left: 8px;
    margin-bottom: 28px;
    text-align: left;
  }
.test-assignment-trash-table svg {
    opacity: 1
  }
.test-assignment-trash-table svg .a {
      fill: #6a6d6d;
      opacity: 1;
    }
.elevate-table-header-cell-filters-wrapper .dropdown-header-custom.expanded {
    display: none;
  }

.delete-assignment-modal .modal-dialog-body .delete-assignment-message {
      display: flex;
      align-items: center;
      justify-content: center;
    }

.delete-assignment-modal .button-wrapper {
    display: flex;
    justify-content: space-evenly
  }

.delete-assignment-modal .button-wrapper .MuiButtonBase-root {
      text-transform: none;
      font-weight: 400;
    }

.restore-footer-modal .modal-dialog-footer {
    display: flex;
    justify-content: center;
  }
