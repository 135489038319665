.checkbox-custom {
  display: inline-block;
  position: relative;
  white-space: nowrap
}

.checkbox-custom .icons-wrapper {
    position: relative
  }

.checkbox-custom .icons-wrapper svg {
      cursor: pointer;
      margin: -3px 8px 0 0;
      vertical-align: middle
    }

.checkbox-custom .icons-wrapper svg .checkbox-a {
        transition: stroke 0.15s linear;
      }

.checkbox-custom .icons-wrapper svg {
      .a {
        fill: #147cbd;
      }
    }

.checkbox-custom .icons-wrapper svg + svg {
      position: absolute;
      top: 50%;
      left: 7px;
      margin: -4px 0 0 0;
    }

.checkbox-custom:hover svg .checkbox-a {
    stroke: #147cbd;
  }

.checkbox-custom input {
    display: none;
  }

.checkbox-custom label {
    margin: 0;
    user-select: none;
    cursor: pointer;
    vertical-align: middle;
  }

.checkbox-custom.border {
  padding: 5px 0
}

.checkbox-custom.border label {
    color: #096da9;
    padding: 6px 13px 6px 8px;
    border-radius: 6px;
    border: 1px solid #d7dae2;
    transition: all 0.15s linear
  }

.checkbox-custom.border label:hover {
      border: 1px solid #147cbd;
    }

.checkbox-custom.radio svg {
    display: none;
  }

.checkbox-custom.radio label {
    padding-left: 30px
  }

.checkbox-custom.radio label:before {
      content: '';
      display: inline-block;
      width: 22px;
      height: 22px;
      border: 2px solid #d7dae2;
      border-radius: 50%;
      position: absolute;
      left: -1px;
      top: 50%;
      margin-top: -12px;
      transition: all 0.15s linear;
    }

.checkbox-custom.radio:hover label:before {
    border-color: #147cbd;
  }

.checkbox-custom.radio.radio-checked label:after {
    content: '';
    display: inline-block;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: #147cbd;
    position: absolute;
    left: 5px;
    top: 50%;
    margin-top: -6px;
    transition: all 0.15s linear;
  }

.checkbox-custom.radio.border label {
    padding-left: 40px
  }

.checkbox-custom.radio.border label:before {
      left: 8px;
    }

.checkbox-custom.radio.border label:after {
      left: 14px;
    }

.checkbox-custom.el-disabled-input label, .checkbox-custom.border.el-disabled-input label, .checkbox-custom-border.el-disabled-input label {
    color: #cccccc;
  }

.checkbox-custom.el-disabled-input .icons-wrapper svg .a, .checkbox-custom.border.el-disabled-input .icons-wrapper svg .a, .checkbox-custom-border.el-disabled-input .icons-wrapper svg .a {
      fill: #cccccc;
    }

.checkbox-custom.el-disabled-input .icons-wrapper svg .checkbox-a, .checkbox-custom.border.el-disabled-input .icons-wrapper svg .checkbox-a, .checkbox-custom-border.el-disabled-input .icons-wrapper svg .checkbox-a {
      stroke: #cccccc;
    }

.checkbox-custom.el-disabled-input:hover label, .checkbox-custom.border.el-disabled-input:hover label, .checkbox-custom-border.el-disabled-input:hover label {
    border-color: #cccccc;
  }
