
  .app-page .app-notification-list {
    position: fixed;
    top: 0em;
    left: 0;
    right: 0;
    z-index: 5;

    transition-property: top, left, right;
    transition-duration: var(--default-transition-duration);
    transition-timing-function: var(--default-transition-timing-function)
  }
.app-page .app-notification-list .MuiCollapse-root {
      width: 100%;
    }
.app-page .app-notification-list .app-notification {
      background-color: var(--app-background-color);
      color: var(--app-text-color);
      padding: 1em 1.5em;
      margin-bottom: 0.0625em;
      box-shadow: 0 0.0625em 0 transparent;
      border-bottom: 0.0625em solid transparent;
      display: grid;
      grid-template-columns: auto 1fr auto;
      align-items: center;
      grid-column-gap: 1.5em;

      transition-property: padding;
      transition-duration: var(--default-transition-duration);
      transition-timing-function: var(--default-transition-timing-function)
    }
.app-page .app-notification-list .app-notification .app-notification-icon circle,
        .app-page .app-notification-list .app-notification .app-notification-icon path {
          fill: #999;
        }
.app-page .app-notification-list .app-notification .app-notification-icon circle {
          opacity: 0.2;
        }
.app-page .app-notification-list .app-notification .app-notification-message {
        display: flex;
        flex-flow: column;
        align-items: flex-start;
        justify-content: center;
        font-weight: 600
      }
.app-page .app-notification-list .app-notification .app-notification-message .app-notification-small-text {
          font-size: 0.875em;
          font-weight: 400;
        }
.app-page .app-notification-list .app-notification .app-notification-tools {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
      }
.app-page .app-notification-list .app-notification .app-notification-timeout-progress {
        pointer-events: none;
      }
.app-page .app-notification-list .app-notification .close-notification-button {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        width: 2em;
        height: 2em;
        border-radius: 50%
      }
.app-page .app-notification-list .app-notification .close-notification-button path {
          transition-property: filter, fill;
          transition-duration: var(--default-transition-duration);
          transition-timing-function: var(--default-transition-timing-function);
          fill: #333;
          filter: drop-shadow(0em 0em 0.05em transparent);
        }
.app-page .app-notification-list .app-notification .close-notification-button:hover,
        .app-page .app-notification-list .app-notification .close-notification-button:focus-visible {
          outline: 2px solid var(--app-background-color-inverse)
        }
.app-page .app-notification-list .app-notification .close-notification-button:hover path, .app-page .app-notification-list .app-notification .close-notification-button:focus-visible path {
            fill: #666;
          }
.app-page .app-notification-list .app-notification.app-notification-default {
        border-bottom-color: var(--app-background-color-inverse);
        box-shadow: 0 0.0625em 0 var(--app-background-color-inverse);
      }
.app-page .app-notification-list .app-notification.app-notification-info {
        border-bottom-color: #46b282;
        box-shadow: 0 0.0625em 0 #46b282
      }
.app-page .app-notification-list .app-notification.app-notification-info .close-notification-button:hover path, .app-page .app-notification-list .app-notification.app-notification-info .close-notification-button:focus-visible path {
              filter: drop-shadow(0em 0em 0.15em #46b282);
            }
.app-page .app-notification-list .app-notification.app-notification-info .app-notification-icon circle,
          .app-page .app-notification-list .app-notification.app-notification-info .app-notification-icon path {
            fill: #46b282;
          }
.app-page .app-notification-list .app-notification.app-notification-info .app-notification-tools circle {
            stroke: #46b282;
          }
.app-page .app-notification-list .app-notification.app-notification-error {
        border-bottom-color: #b12e2e;
        box-shadow: 0 0.0625em 0 #b12e2e
      }
.app-page .app-notification-list .app-notification.app-notification-error .close-notification-button:hover path, .app-page .app-notification-list .app-notification.app-notification-error .close-notification-button:focus-visible path {
              filter: drop-shadow(0em 0em 0.15em #933);
            }
.app-page .app-notification-list .app-notification.app-notification-error .app-notification-icon circle,
          .app-page .app-notification-list .app-notification.app-notification-error .app-notification-icon path {
            fill: #b12e2e;
          }
.app-page .app-notification-list .app-notification.app-notification-error .app-notification-tools circle {
            stroke: #b12e2e;
          }
.app-page .app-notification-list .app-notification.app-notification-warning,
      .app-page .app-notification-list .app-notification.app-notification-warn {
        border-bottom-color: #f80;
        box-shadow: 0 0.0625em 0 #f80
      }
.app-page .app-notification-list .app-notification.app-notification-warning .close-notification-button:hover path, .app-page .app-notification-list .app-notification.app-notification-warning .close-notification-button:focus-visible path, .app-page .app-notification-list .app-notification.app-notification-warn .close-notification-button:hover path, .app-page .app-notification-list .app-notification.app-notification-warn .close-notification-button:focus-visible path {
              filter: drop-shadow(0em 0em 0.15em #f80);
            }
.app-page .app-notification-list .app-notification.app-notification-warning .app-notification-icon circle,
          .app-page .app-notification-list .app-notification.app-notification-warning .app-notification-icon path,
          .app-page .app-notification-list .app-notification.app-notification-warn .app-notification-icon circle,
          .app-page .app-notification-list .app-notification.app-notification-warn .app-notification-icon path {
            fill: #f80;
          }
.app-page .app-notification-list .app-notification.app-notification-warning .app-notification-tools circle, .app-page .app-notification-list .app-notification.app-notification-warn .app-notification-tools circle {
            stroke: #f80;
          }
.app-page .app-notification-list .app-notification.persistent-app-notification .close-notification-button {
          margin-right: 2.6em;
          width: 2rem;
          height: 2rem
        }
.app-page .app-notification-list .app-notification.persistent-app-notification .close-notification-button svg {
            width: 100%;
            height: 100%;
          }
.app-page.has-app-header .app-notification-list {
      top: var(--app-header-height);
    }
.app-page.has-app-header.has-app-header-toolbar .app-notification-list {
        top: calc(var(--app-header-height) + var(--app-toolbar-height));
      }
.app-page.has-app-header.app-overlay-visible .app-notification-list {
        /* top: 0em; */
        z-index: 12;
      }
.app-page.has-app-header.has-app-debug .app-notification-list {
        right: var(--app-debug-width);
      }
.app-page .app-layout-root:has(.has-visible-menu) + .app-notification-list {
      left: var(--app-menu-width);
    }
.app-page .app-layout-root:has(.has-visible-menu):has(.has-expanded-menu) + .app-notification-list {
        left: var(--app-menu-expanded-width);
      }
.app-page .app-layout-root:has(.has-visible-menu) {
    /* &:has(.page-wrapper.app-overlay-visible) {
      & + .app-notification-list {
        left: 0em;
      }
    } */
  }
.app-page .modal-dialog-initialized + .app-layout-root + .app-notification-list {
      top: 0em
    }
.app-page .modal-dialog-initialized + .app-layout-root + .app-notification-list .app-notification {
        padding: 0.4em 1.5em;
      }
.app-page .modal-dialog-initialized + .app-layout-root:has(.has-visible-menu) + .app-notification-list {
        left: 0em;
      }
