
  .app-page .modal-dialog-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 10;
    opacity: 0;

    transition-property: opacity, right;
    transition-duration: 0.3s;
    transition-timing-function: ease-in-out
  }

.app-page .modal-dialog-wrapper .modal-dialog-mask {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: rgba(255, 255, 255, 0.5);
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: center
    }

.app-page .modal-dialog-wrapper .modal-dialog-mask.closable-modal-dialog-mask {
        cursor: pointer;
      }

.app-page .modal-dialog-wrapper .close-modal-button {
      cursor: pointer;
      align-self: end;
    }

.app-page .modal-dialog-wrapper .modal-dialog-contents {
      cursor: initial;
      background: #ffffff;
      border: 1px solid #d7dae2;
      border-radius: 0.375em;
      padding: 0.625em;
      max-width: calc(90vw - 1.25em);
      min-width: 30vw;
      max-height: 90vh;

      transition-property: margin-top, max-height;
      transition-duration: 0.3s;
      transition-timing-function: ease-in-out;
    }

.app-page .modal-dialog-wrapper .modal-dialog-top {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      justify-content: space-between;
      padding: 0.5em
    }

.app-page .modal-dialog-wrapper .modal-dialog-top .icon-link {
        font-size: 2em;
      }

.app-page .modal-dialog-wrapper .modal-dialog-title {
      flex: 1 0 auto;
      text-align: center;
      color: #096da9;
      font-weight: bold;
    }

.app-page .modal-dialog-wrapper .modal-dialog-body {
      padding: 2em 0;
      max-height: 80vh;
      overflow: auto;
    }

.app-page .modal-dialog-wrapper .modal-dialog-footer {
      padding: 1em 0;
    }

.app-page .modal-dialog-wrapper.modal-dialog-initialized {
      opacity: 1;
    }

.app-page.has-app-notification .modal-dialog-wrapper .modal-dialog-contents {
        margin-top: 4em;
        max-height: calc(90vh - 4em);
      }

.app-loader-root {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  background: rgba(255, 255, 255, 0.9);
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}

.app-loader-title {
  font-size: 2.6em;
  font-weight: 500;
}

.app-loader-body {
  margin-top: 1em;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}

.app-loader-message,
.app-loader-actions {
  margin-top: 1.5em;
}

.overlay-loader-root {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  background: rgba(255, 255, 255, 0.9);
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}

.overlay-loader-title {
  font-size: 2.6em;
  font-weight: 500;
}

.overlay-loader-body {
  margin-top: 1em;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}

.overlay-loader-message,
.overlay-loader-actions {
  margin-top: 1.5em;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader-spinner {
  animation: spin 2s linear infinite;
}

.overlay-error-root {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  background: rgba(255, 255, 255, 0.9);
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}

.overlay-error-title {
  font-size: 2.6em;
  font-weight: 500;
}

.overlay-error-body {
  margin-top: 1em;
  margin-bottom: 1em;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}

.overlay-error-message,
.overlay-error-actions {
  margin-top: 1.5em;
}

/* .header-menu-button {
    background: none;
    border: none;
    color: #ffffff;
    margin: 0;
    padding: 0;
    height: 2em;
    z-index: 13;
    position: relative;
} */
/* 
.header-nav-icon-button {
    background: none;
    border: none;
    color: #ffffff;
} */

header.app-header .app-header-bar {
    background-color: #096da9
  }

header.app-header .app-header-bar .app-menu-toolbar {
      padding: 0;
      min-width: var(--app-menu-width);
    }

header.app-header .app-header-bar .app-header-navigation .app-menu-top-item .open-header-menu-button {
          width: var(--app-menu-width);
          height: var(--app-menu-width);
          display: flex;
          align-items: center;
          justify-content: center;
          background: none;
          border: none;
          /* color: #ffffff; */
          cursor: pointer
        }

header.app-header .app-header-bar .app-header-navigation .app-menu-top-item .open-header-menu-button svg {
            width: 1.1rem
            /* height: 1.1rem; */
          }

header.app-header .app-header-bar .app-header-navigation .app-menu-top-item .open-header-menu-button svg rect {
              fill: #d8e1e9;

              transition-property: fill, width;
              transition-duration: 0.15s;
              transition-timing-function: ease-in-out
            }

header.app-header .app-header-bar .app-header-navigation .app-menu-top-item .open-header-menu-button svg rect:nth-child(3n + 1) {
                width: 70%;
              }

header.app-header .app-header-bar .app-header-navigation .app-menu-top-item .open-header-menu-button svg rect:nth-child(3n + 2) {
                width: 100%;
              }

header.app-header .app-header-bar .app-header-navigation .app-menu-top-item .open-header-menu-button svg rect:nth-child(3n + 3) {
                width: 85%;
              }

header.app-header .app-header-bar .app-header-navigation .app-menu-top-item .open-header-menu-button:hover svg rect {
                fill: #d8e1e9
              }

header.app-header .app-header-bar .app-header-navigation .app-menu-top-item .open-header-menu-button:hover svg rect:nth-child(3n + 1) {
                  width: 100%;
                }

header.app-header .app-header-bar .app-header-navigation .app-menu-top-item .open-header-menu-button:hover svg rect:nth-child(3n + 2) {
                  width: 100%;
                }

header.app-header .app-header-bar .app-header-navigation .app-menu-top-item .open-header-menu-button:hover svg rect:nth-child(3n + 3) {
                  width: 100%;
                }

header.app-header .app-header-bar .app-menu-wrapper {
      height: 100vh;
      width: var(--app-menu-width);
      /* z-index: 15; */
      /* top: 0; */
      /* left: 0; */
      background: #ffffff;
      /* box-shadow: 10px 0px 0px rgba(0, 0, 0, 0.2); */
      display: grid;
      /* grid-template-columns: auto; */
      grid-template-rows: 4rem auto 7rem;
      /* grid-gap: 0 0; */
      grid-row-gap: 0.25rem;
      align-items: flex-start;
      transition-property: width;
      transition-duration: var(--default-transition-duration);
      transition-timing-function: var(--default-transition-timing-function);
      overflow: hidden
    }

header.app-header .app-header-bar .app-menu-wrapper .app-menu-top {
        height: 4rem;
        /* min-height: 3.8em; */
        /* max-width: 4rem; */
        /* max-width: var(--app-menu-width); */
        display: flex;
        align-items: center;
        justify-content: flex-start;
        /* border-bottom: 1px solid #d7dae2; */
        box-shadow: -0.675rem 0.125rem 0.125rem #d7dae222;
        padding-left: 1.5rem
      }

header.app-header .app-header-bar .app-menu-wrapper .app-menu-top .close-header-menu-button {
          background: none;
          border: none;
          /* color: #ffffff; */
          margin: 0;
          padding: 0;
          /* height: 2em; */
          /* z-index: 13; */
          /* position: relative; */
          cursor: pointer
        }

header.app-header .app-header-bar .app-menu-wrapper .app-menu-top .close-header-menu-button svg {
            height: 1.3em;
            width: 1.3em;
            transition-property: transform;
            transition-duration: var(--default-transition-duration);
            transition-timing-function: var(--default-transition-timing-function)
          }

header.app-header .app-header-bar .app-menu-wrapper .app-menu-top .close-header-menu-button svg path {
              fill: #6a6d6d;
              transition-property: fill;
              transition-duration: var(--default-transition-duration);
              transition-timing-function: var(--default-transition-timing-function);
            }

header.app-header .app-header-bar .app-menu-wrapper .app-menu-top .close-header-menu-button:hover svg {
              transform: rotate(90deg)
            }

header.app-header .app-header-bar .app-menu-wrapper .app-menu-top .close-header-menu-button:hover svg path {
                fill: #096da9;
              }

header.app-header .app-header-bar .app-menu-wrapper .app-menu-middle {
        overflow: auto;
        height: calc(100vh - 11rem);
      }

header.app-header .app-header-bar .app-menu-wrapper .app-menu {
        margin: 0;
        padding: 0
        /* z-index: 12; */
        /* width: auto; */
        /* overflow: auto; */
        /* max-width: var(--app-menu-width); */
      }

header.app-header .app-header-bar .app-menu-wrapper .app-menu > li {
          margin: 0;
          padding: 0
        }

header.app-header .app-header-bar .app-menu-wrapper .app-menu > li .nav-item {
            display: flex;
            align-items: stretch;
            justify-content: center;
            min-height: 3.8em;
            font-size: 0.9rem;
            border-width: 0 0.25em 0 0.25em;
            border-style: solid;
            border-color: transparent;
            /* max-width: 3.55rem; */
            text-decoration: none
          }

header.app-header .app-header-bar .app-menu-wrapper .app-menu > li .nav-item .expanded-nav-item {
              color: #6a6d6d;
              display: flex;
              white-space: nowrap;
              word-break: keep-all;
              flex-flow: row nowrap;
              padding-left: 1.25rem;
              flex: 1 1 auto;
              overflow: hidden;
              align-items: center;
              justify-content: flex-start;
              gap: 1.25rem
              /* max-width: 4rem; */
            }

header.app-header .app-header-bar .app-menu-wrapper .app-menu > li .nav-item .expanded-nav-item svg {
                flex: 0 0 1.2rem;
                /* width: 1em; */
              }

header.app-header .app-header-bar .app-menu-wrapper .app-menu > li .nav-item .expanded-nav-item .expanded-nav-item-text {
                text-decoration: none;
                /* display: none; */
                flex: 1 1 auto;
                overflow: hidden;
                white-space: nowrap;
                word-break: keep-all;
                display: flex;
                flex-flow: row nowrap;
                flex: 1 1 auto;
              }

header.app-header .app-header-bar .app-menu-wrapper .app-menu > li .nav-item .expanded-nav-item .expanded-nav-item-suffix {
                flex: 0 0 2ch;
              }

header.app-header .app-header-bar .app-menu-wrapper .app-menu > li .nav-item svg path {
              fill: #6a6d6d;
            }

header.app-header .app-header-bar .app-menu-wrapper .app-menu > li .nav-item:hover {
              background: #f6f6f6
            }

header.app-header .app-header-bar .app-menu-wrapper .app-menu > li .nav-item:hover svg path {
                fill: #147cbd;
              }

header.app-header .app-header-bar .app-menu-wrapper .app-menu > li .nav-item.active {
              border-left-color: #147cbd
            }

header.app-header .app-header-bar .app-menu-wrapper .app-menu > li .nav-item.active .expanded-nav-item {
                color: #147cbd;
              }

header.app-header .app-header-bar .app-menu-wrapper .app-menu > li .nav-item.active svg path {
                fill: #147cbd;
              }

header.app-header .app-header-bar .app-menu-wrapper .app-menu > li button.nav-item {
            display: flex;
            width: 100%;
            align-items: stretch;
            justify-content: stretch;
            cursor: pointer;
            flex: 1 0 auto;
            padding: 0;
            background: none
          }

header.app-header .app-header-bar .app-menu-wrapper .app-menu > li button.nav-item.active {
              border-left-color: #147cbd
            }

header.app-header .app-header-bar .app-menu-wrapper .app-menu > li button.nav-item.active svg path {
                fill: #147cbd;
              }

header.app-header .app-header-bar .app-menu-wrapper .app-menu .app-submenu {
          margin: 0;
          padding: 0;
          display: flex;
          height: 100vh;
          flex-flow: column;
          position: fixed;
          z-index: 12;
          top: 0;
          left: var(--app-menu-width);
          background-color: #f8f8f8;
          overflow-x: hidden;
          /* box-shadow: 10px 0px 0px rgba(0, 0, 0, 0.2); */
          box-shadow: 0.5rem 0 0 #00000014;
          background: #f9f9f9;

          opacity: 0;
          pointer-events: none;
          transition-property: opacity, left;
          transition-duration: 0.3s;
          transition-timing-function: ease-in-out
        }

header.app-header .app-header-bar .app-menu-wrapper .app-menu .app-submenu .sub-menu-top-item {
            display: flex;
            padding: 0;
            align-items: center;
            justify-content: stretch;
            margin: 0;
            background: #147cbd;
            height: var(--app-header-height);
            overflow: hidden;
            width: 100%

            /* & > div {
                            width: 100% !important;
                        } */
          }

header.app-header .app-header-bar .app-menu-wrapper .app-menu .app-submenu .sub-menu-top-item .sub-menu-close-button {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              gap: 1em;
              padding: 0.2em 0.6em;
              margin-left: 1em;
              font-size: 1em;
              font-weight: 600;
              border: none;
              background: none;
              color: #ffffff;
              border-radius: 0.2em
            }

header.app-header .app-header-bar .app-menu-wrapper .app-menu .app-submenu .sub-menu-top-item .sub-menu-close-button svg {
                opacity: 0.5;

                transition-property: opacity;
                transition-duration: var(--default-transition-duration);
                transition-timing-function: var(--default-transition-timing-function);
              }

header.app-header .app-header-bar .app-menu-wrapper .app-menu .app-submenu .sub-menu-top-item .sub-menu-close-button:focus svg, header.app-header .app-header-bar .app-menu-wrapper .app-menu .app-submenu .sub-menu-top-item .sub-menu-close-button:hover svg {
                  opacity: 1;
                }

header.app-header .app-header-bar .app-menu-wrapper .app-menu .app-submenu .sub-menu-top-item .sub-menu-close-button:focus {
                outline: 0.125em solid #ffffff;
              }

header.app-header .app-header-bar .app-menu-wrapper .app-menu .app-submenu .sub-menu-top-item .sub-menu-close-tooltip {
              background: #096da9cc;
              border: 0.125em solid #ffffffcc
            }

header.app-header .app-header-bar .app-menu-wrapper .app-menu .app-submenu .sub-menu-top-item .sub-menu-close-tooltip:after {
                border-right-color: #ffffff99;
              }

header.app-header .app-header-bar .app-menu-wrapper .app-menu .app-submenu .sub-menu-item .sub-nav-item {
              display: block;
              min-width: 25ch;
              padding: 0.8em;
              margin: 0 0 0.1em 0;
              background: #f9f9f9;
              color: #6a6d6d;
              box-shadow: 0px 1px 0px #e5e5e5;
              /* font-size: 0.75em; */
              text-decoration: none;
              font-weight: 500;
              border-width: 0 0.25em 0 0.25em;
              border-style: solid;
              border-color: transparent
            }

header.app-header .app-header-bar .app-menu-wrapper .app-menu .app-submenu .sub-menu-item .sub-nav-item:hover {
                background: #f6f6f6;
                color: #147cbd;
              }

header.app-header .app-header-bar .app-menu-wrapper .app-menu .app-submenu .sub-menu-item .sub-nav-item.active {
                border-right-color: #096da9;
                color: #096da9;
              }

header.app-header .app-header-bar .app-menu-wrapper .app-menu .app-submenu .sub-menu-item.app-submenu-entry-disabled .sub-nav-item {
                color: #ccc;
              }

header.app-header .app-header-bar .app-menu-wrapper .app-menu .app-menu-entry {
          position: relative
        }

header.app-header .app-header-bar .app-menu-wrapper .app-menu .app-menu-entry .nav-item {
            transition-property: border-right-color;
            transition-timing-function: ease-in-out;
            transition-duration: 0.3s;

            display: flex;
            flex-flow: column;
            align-items: stretch;
            justify-content: stretch;
          }

header.app-header .app-header-bar .app-menu-wrapper .app-menu .app-menu-entry .app-menu-entry-contents {
            flex: 1 0 100%;
            display: flex;
            justify-content: center;
            align-items: center;
          }

header.app-header .app-header-bar .app-menu-wrapper .app-menu .app-menu-entry .app-menu-entry-logo {
            flex: 1 0 100%;
            display: flex;
            justify-content: center;
            align-items: center;
          }

header.app-header .app-header-bar .app-menu-wrapper .app-menu .app-menu-entry .app-submenu .sub-nav-item .app-menu-entry-contents {
                justify-content: flex-start;
              }

header.app-header .app-header-bar .app-menu-wrapper .app-menu .app-menu-entry.app-menu-entry-open .nav-item {
              border-right-color: #147cbd;
              border-left-color: #147cbd;
              color: #147cbd
            }

header.app-header .app-header-bar .app-menu-wrapper .app-menu .app-menu-entry.app-menu-entry-open .nav-item svg path {
                  fill: #096da9;
                }

header.app-header .app-header-bar .app-menu-wrapper .app-menu .app-menu-entry.app-menu-entry-open .nav-item .expanded-nav-item {
                color: #147cbd;
              }

header.app-header .app-header-bar .app-menu-wrapper .app-menu .app-menu-entry.app-menu-entry-open .app-submenu {
              opacity: 1;
              pointer-events: all;
            }

header.app-header .app-header-bar .app-menu-wrapper .app-menu .app-menu-entry.app-menu-entry-hidden {
            visibility: hidden;
            pointer-events: none;
          }

header.app-header .app-header-bar .app-menu-wrapper .app-menu .app-menu-entry.app-menu-entry-disabled {
            opacity: 0.5;
            cursor: not-allowed;
          }

header.app-header .app-header-bar .app-menu-wrapper .app-menu .disabled-nav-item {
          pointer-events: none;
          cursor: not-allowed;
        }

header.app-header .app-header-bar .app-menu-wrapper {

      /* & .appmenubottom {
                align-self: flex-end;
                text-align: center;
                background: #ffffff;
                z-index: 12;

                & .logobottom {
                    border-top: 1px solid #d7dae2;
                    padding-top: 1em;
                    padding-bottom: 0.5em;
                }
            } */
    }

header.app-header .app-header-bar .app-menu-wrapper .appmenubottom {
        border-top: 1px solid #d7dae2;
        background-color: #ffffff;
        height: 7rem;
        z-index: 13;
        display: flex;
        flex-flow: column;
        align-items: flex-start;
        justify-content: space-evenly;
        max-width: var(--app-menu-width)
      }

header.app-header .app-header-bar .app-menu-wrapper .appmenubottom .expand-sidebar-wrapper {
          align-self: flex-start;
          margin-left: 1rem
        }

header.app-header .app-header-bar .app-menu-wrapper .appmenubottom .expand-sidebar-wrapper .expand-sidebar-button {
            width: 2rem;
            height: 2rem;
            color: var(--app-button-text-color);
            background: var(--app-button-background-color);
            border: var(--app-button-border);
            border-radius: var(--app-button-border-radius);
            letter-spacing: 0px;
            display: flex;
            flex-flow: row nowrap;
            gap: 0em;
            align-items: center;
            justify-content: center;
            font-size: 1.6rem;
          }

header.app-header .app-header-bar .app-menu-wrapper .appmenubottom .logobottom {
          padding-left: 1.4rem
        }

header.app-header .app-header-bar .app-menu-wrapper .appmenubottom .logobottom svg .logobottom-svg-text-paths {
              opacity: 0;
              transition-property: opacity;
              transition-duration: var(--default-transition-duration);
              transition-timing-function: var(--default-transition-timing-function);
            }

header.app-header .app-header-bar .app-menu-wrapper .menu-entry-tooltip {
      }

header.app-header .app-header-bar .app-menu-wrapper .sub-menu-entry-tooltip {
      }

header.app-header .app-header-bar .app-menu-wrapper {

      /* &.app-menu-wrapper-expanded {
                & .app-menu {
                    & > li {
                        & .nav-item {
                            & .expanded-nav-item {
                                & .expanded-nav-item-text {
                                    display: unset;
                                }
                            }
                        }
                    }
                }
            } */
    }

header.app-header.has-visible-menu.has-expanded-menu .app-header-bar .app-menu-wrapper {
          overflow: hidden;
          /* width: 12rem; */
          width: var(--app-menu-expanded-width);
          background: #fff
        }

header.app-header.has-visible-menu.has-expanded-menu .app-header-bar .app-menu-wrapper .app-submenu {
            /* left: 12.5rem; */
            /* left: calc(var(--app-menu-expanded-width) + 0.5rem); */
            left: var(--app-menu-expanded-width);
          }

header.app-header.has-visible-menu.has-expanded-menu .app-header-bar .app-menu-wrapper .app-menu {
            /* max-width: unset; */
            max-width: var(--app-menu-expanded-width)
          }

header.app-header.has-visible-menu.has-expanded-menu .app-header-bar .app-menu-wrapper .app-menu > li .nav-item {
                /* max-width: unset; */
              }

header.app-header.has-visible-menu.has-expanded-menu .app-header-bar .app-menu-wrapper .app-menu > li .nav-item .expanded-nav-item .expanded-nav-item-text {
                    /* display: unset; */
                  }

header.app-header.has-visible-menu.has-expanded-menu .app-header-bar .app-menu-wrapper .appmenubottom {
            max-width: var(--app-menu-expanded-width)
          }

header.app-header.has-visible-menu.has-expanded-menu .app-header-bar .app-menu-wrapper .appmenubottom .expand-sidebar-button svg {
                transform: rotate(180deg);
              }

header.app-header.has-visible-menu.has-expanded-menu .app-header-bar .app-menu-wrapper .appmenubottom .logobottom svg .logobottom-svg-text-paths {
                  /* display: unset; */
                  opacity: 1;
                }
.app-menu-tooltip-contents {
  font-size: 1.3em;
  padding: 0.3em 0.6em;
}

header.app-header {
  color: var(--text-color-inverse);
  height: 0em;
  z-index: 6;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;

  transition-property: height, border-top;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out
}

header.app-header .app-menu-drawer {
    overflow: hidden
  }

header.app-header .app-menu-drawer .MuiPaper-root {
      overflow: hidden;
      max-height: 100vh;
      background: transparent;
      box-shadow: 0.5rem 0 0 #00000014;
    }

header.app-header .app-header-bar {
    background-color: var(--app-background-color-inverse);
    box-shadow: none;
    border-right: 1px solid rgba(155, 155, 155, 0.5);
    width: 100%;
    height: 0em;
    position: relative;
    overflow: hidden;
    display: grid;
    grid-template-columns: var(--app-menu-width) auto 1fr;
    justify-content: center;
  }

header.app-header .app-header-title {
    display: flex;
    align-items: center
  }

header.app-header .app-header-title .app-header-title-section {
      display: flex;
      margin-left: 0.8ch;
      border-left: 1px solid var(--text-color-inverse);
      padding-left: 0.8ch;
      line-height: 0.8em
    }

header.app-header .app-header-title .app-header-title-section:first-child {
        margin-left: 0;
        padding-left: 0;
        border-left: none;
      }

header.app-header .app-header-title .app-header-crumb-link {
      color: var(--text-color-inverse);
      text-decoration: none;
    }

header.app-header .app-header-section-main {
    display: flex;
    align-items: center;
    transition-property: margin-left;
    transition-duration: 0.3s;
    transition-timing-function: ease-in-out;
    margin-left: 1rem
  }

header.app-header .app-header-section-main .elevate-header-title-placeholder {
      width: 40ch;
      height: 1.4em;
    }

header.app-header .app-header-section-right {
    flex: 0 1 auto;
    margin-right: 0;
    align-self: stretch;
    display: flex;
    align-items: center;
    margin-left: auto;
    padding: 0 0 0 0.625em;
    position: relative
  }

header.app-header .app-header-section-right .app-header-user-info {
      height: 100%;
      font-size: 0.85em;
      font-weight: 100;
      padding-right: 0.625em;
      padding-left: 0.625em;
      border-right: 1px solid rgba(255, 255, 255, 0.4);
      border-left: 1px solid rgba(255, 255, 255, 0.4);
      color: var(--text-color-inverse);
      display: flex;
      flex-flow: column;
      justify-content: center;
      padding: 0 2ch 0 2ch
    }

header.app-header .app-header-section-right .app-header-user-info .app-header-user-info-data {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 2ch;
      }

header.app-header .app-header-section-right .app-header-user-info .app-header-user-info-placeholder {
        height: 1.5em;
        min-width: 25ch
      }

header.app-header .app-header-section-right .app-header-user-info .app-header-user-info-placeholder:first-child {
          min-width: 35ch;
        }

@media only screen and (max-width: 993px) {
        header.app-header .app-header-section-right .app-header-user-info .header-user-info-name {
          display: none;
        }
      }

header.app-header .app-header-section-right .app-header-user-info .white {
        color: #fff;
      }

header.app-header .app-header-section-right .app-header-user-info .chevron-button {
        background-color: Transparent;
        background-repeat: no-repeat;
        border: none;
        cursor: pointer;
        overflow: hidden
      }

header.app-header .app-header-section-right .app-header-user-info .chevron-button .chevron {
          height: 2.5ch;
          width: 2.5ch;
          min-height: 2.5ch;
          min-width: 2.5ch;
          transition: transform var(--default-transition-duration) ease;
        }

header.app-header .app-header-section-right .app-header-user-info .chevron-button .expanded {
          transform: rotate(180deg);
        }

header.app-header .app-header-section-right .app-header-avatar-wrapper {
      padding: 0 0.3em;
      align-self: stretch;
      justify-self: stretch;
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: 1px solid transparent;

      transition-property: background, border-color;
      transition-duration: 0.3s;
      transition-timing-function: ease-in-out
    }

header.app-header .app-header-section-right .app-header-avatar-wrapper .app-header-avatar-placeholder {
        width: 3.125em;
        height: 3.125em;
      }

header.app-header .app-header-section-right .app-header-avatar-wrapper .app-header-avatar {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-flow: column
      }

header.app-header .app-header-section-right .app-header-avatar-wrapper .app-header-avatar .app-header-avatar-button {
          box-shadow: 0 0 2px #fff;
          padding: 0;
          background: none;
          border: none;
          outline: none;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          width: 3.125em;
          height: 3.125em;
          background: #36a9de;
          color: var(--text-color-inverse)
        }

header.app-header .app-header-section-right .app-header-avatar-wrapper .app-header-avatar .app-header-avatar-button img {
            max-width: calc(100% - 0.815em);
          }

header.app-header .app-header-section-right .app-header-avatar-wrapper.app-header-avatar-wrapper-default .app-header-avatar .app-header-avatar-button path {
              fill: #ffffff;
            }

header.app-header .app-header-section-right .app-header-avatar-wrapper:hover .app-header-avatar .app-header-avatar-button {
            background: #0e5b8b;
          }

header.app-header .app-header-section-right.avatar-menu-active .app-header-avatar-wrapper {
        border-bottom-color: #096da933;
        background: #0e5b8b
      }

header.app-header .app-header-section-right.avatar-menu-active .app-header-avatar-wrapper .app-header-avatar .app-header-avatar-button {
            background: #0e5b8b;
          }

header.app-header .header-menu-button path {
      fill: #ffffff;
    }

header.app-header .light-mui-progress {
    color: #ffffff;
  }

header.app-header .header-title-loader circle {
      stroke: #ffffff;
    }

header.app-header .elevate-header-toolbar-wrapper {
    position: sticky;
    background: #0e5b8b;
    top: 4em;
    width: 100%;
    min-height: 0rem;
    max-height: 0rem;
    overflow: hidden;

    transition-property: min-height;
    transition-duration: var(--default-transition-duration);
    transition-timing-function: var(--default-transition-timing-function)
  }

header.app-header .elevate-header-toolbar-wrapper .elevate-header-toolbar {
      /* background: #0E5B8B; */
      color: #f8f8f8;
      margin-left: 0rem;
      height: 3rem;
      /* height: 0em; */

      transition-property: margin-left, width;
      transition-duration: var(--default-transition-duration);
      transition-timing-function: var(--default-transition-timing-function);
      /* overflow: hidden; */
    }

header.app-header .elevate-header-toolbar-wrapper.elevate-header-toolbar-wrapper-active {
      min-height: 3rem;
      max-height: 3rem
    }

header.app-header .elevate-header-toolbar-wrapper.elevate-header-toolbar-wrapper-active .elevate-header-toolbar {
      }

header.app-header.app-header-visible {
    height: 4em
  }

header.app-header.app-header-visible .app-header-bar {
      height: 4em;
      overflow: visible;
    }

header.app-header.has-header-toolbar + .page-wrapper {
      margin-top: 3em;
    }

header.app-header .menu-entry-tooltip {
    box-shadow: 0 0 0.25em #000000;
  }

header.app-header .sub-menu-entry-tooltip {
    box-shadow: 0 0 0.25em #888888;
  }

header.app-header.invisible-element {
    visibility: hidden;
  }

header.app-header.app-header-busy {
    /* border-top: 1px solid #FFFFFF99; */
  }

header.app-header.has-visible-menu .app-header-section-main {
      /* margin-left: 1rem; */
    }

header.app-header.has-visible-menu .elevate-header-toolbar-wrapper .elevate-header-toolbar {
        /* width: calc(100% - var(--app-menu-width)); */
        margin-left: var(--app-menu-width);
      }

header.app-header.has-visible-menu.has-expanded-menu .app-header-section-main {
        margin-left: calc(1em + var(--app-menu-expanded-width) - var(--app-menu-width));
        /* margin-left: 9rem; */
      }

header.app-header.has-visible-menu.has-expanded-menu .elevate-header-toolbar-wrapper .elevate-header-toolbar {
          /* width: calc(100% - var(--app-menu-expanded-width)); */
          margin-left: var(--app-menu-expanded-width);
        }

.user-info-header-tooltip {
  font-weight: 300;
  padding: 0.2em 0.4em;
  font-size: 1.2em;
  line-height: 1.4em;
  color: #fcfcff
}

.user-info-header-tooltip strong {
    font-weight: 600;
  }

.user-info-header-tooltip .user-info-header-tooltip-row {
    display: flex;
    align-items: center;
    justify-content: space-between
  }

.user-info-header-tooltip .user-info-header-tooltip-row span {
      margin-left: 0.5em;
    }

.user-info-header-tooltip .user-info-header-tooltip-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(auto, 33%));
    justify-content: center
  }

.user-info-header-tooltip .user-info-header-tooltip-grid span,
    .user-info-header-tooltip .user-info-header-tooltip-grid strong {
      display: block;
      text-align: center;
    }

.app-header-avatar-menu-wrapper.MuiMenu-paper {
  position: absolute;
  right: 0.125em;
  margin-left: auto;
  margin-top: 2.25em;
  width: 20ch;
  background: transparent;
  box-shadow: none;
  padding: 0 0 0.6em 0.6em
}

.app-header-avatar-menu-wrapper.MuiMenu-paper .app-header-avatar-menu-list {
    padding-bottom: 0;
  }

.app-header-avatar-menu-wrapper.MuiMenu-paper .right-menu-arrow {
    text-align: right;
    overflow: hidden;
    height: 0.5em
  }

.app-header-avatar-menu-wrapper.MuiMenu-paper .right-menu-arrow svg {
      position: relative;
      top: -0.28em;
      right: 1.25em;
      transform: rotate(180deg);
      height: 1em;
      width: 1em
    }

.app-header-avatar-menu-wrapper.MuiMenu-paper .right-menu-arrow svg polygon {
        fill: #fff;

        stroke: #333;
        stroke-width: 2px;
      }

.app-header-avatar-menu-wrapper.MuiMenu-paper .right-menu-entry {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* box-shadow: 0px 1px 0px #F1F1F1; */
    border-bottom: 1px solid #f1f1f1;
    /* border-top: 1px solid #F100F1; */
    box-shadow: 0 0.3em 0.3em #ccc;
    padding: 0.6em 1em;
    background: #fff;
    color: #096da9;

    transition-property: color, border, background;
    transition-duration: 0.3s;
    transition-timing-function: ease-in-out;
    border-radius: 0.2em 0.2em 0 0
  }

.app-header-avatar-menu-wrapper.MuiMenu-paper .right-menu-entry .right-menu-entry-text {
      flex: 1 0 auto;
    }

.app-header-avatar-menu-wrapper.MuiMenu-paper .right-menu-entry .right-menu-entry-icon {
      flex: 0 1 auto;
      display: flex;
      align-items: center;
      justify-content: flex-end
    }

.app-header-avatar-menu-wrapper.MuiMenu-paper .right-menu-entry .right-menu-entry-icon svg {
        display: inline-block;
        max-height: 1.3em;
        max-width: 1.5em
      }

.app-header-avatar-menu-wrapper.MuiMenu-paper .right-menu-entry .right-menu-entry-icon svg path {
          transition-property: fill;
          transition-duration: 0.3s;
          transition-timing-function: ease-in-out;

          fill: #096da9;
        }

.app-header-avatar-menu-wrapper.MuiMenu-paper .right-menu-entry + .right-menu-entry {
      border-radius: 0;
    }

.app-header-avatar-menu-wrapper.MuiMenu-paper .right-menu-entry:last-of-type {
      border-bottom: none;
      border-radius: 0 0 0.2em 0.2em;
    }

.app-header-avatar-menu-wrapper.MuiMenu-paper .right-menu-entry:hover {
      border-color: #096da9;
      background: #096da9;
      color: #fff
    }

.app-header-avatar-menu-wrapper.MuiMenu-paper .right-menu-entry:hover .right-menu-entry-icon svg path {
            fill: #fff;
          }

/* header.app-header {
    display: flex;
    align-items: center;
}

header.app-header .app-header-bar {
}

.app-header-section {
    margin: 0 5px;
}

.app-header-section:first-child {
    margin-left: 0;
}

.app-header-section:last-child {
    margin-right: 0;
}

.app-header-section-right {
    & .MuiCircularProgress-svg {
        & circle {
            stroke: #FFF;
        }
    }
} */

.elevate-header-user-info-tooltip .elevate-user-info-loader {
    font-size: 1.1em;
    font-weight: 300;
    min-width: 20ch;
    text-align: center;
    padding: 0.4em;
  }
.elevate-header-user-info-tooltip-grid {
  display: grid;
  grid-template-columns: 8ch minmax(auto, 40ch);
  grid-gap: 0.5em 0.3em;
  justify-content: flex-start
}
.elevate-header-user-info-tooltip-grid span:nth-child(2n + 1) {
    text-align: right;
    font-weight: 600;
  }
.elevate-header-user-info-tooltip-grid span:nth-child(2n) {
    max-height: 9.25em;
    overflow: auto
  }
.elevate-header-user-info-tooltip-grid span:nth-child(2n) .user-info-item-name {
      word-break: keep-all;
      color: #dddddd
    }
.elevate-header-user-info-tooltip-grid span:nth-child(2n) .user-info-item-name:after {
        content: ', ';
      }
.elevate-header-user-info-tooltip-grid span:nth-child(2n) .user-info-item-name:last-of-type:after {
          content: '';
          display: none;
        }
.elevate-header-user-info-tooltip-grid span:nth-child(2n) .user-info-item-name:hover {
        color: #ffffff;
        text-shadow: 0 0 2px #ffffff;
      }

.elevate-tooltip-wrapper-user-info .MuiTooltip-tooltip {
    background: #000000;
    padding: 0.5em 4em 0.7em 4em;
    margin: 0;
    border-radius: 0;
  }

.elevate-tooltip-wrapper-user-info .MuiTooltip-arrow {
    color: #000;
  }

.elevate-tooltip-wrapper .MuiTooltip-tooltip {
    background: #000000;
    padding: 0.5em 0.8em 0.7em 0.8em;
    margin: 0;
    border-radius: 0.625em;
  }

.elevate-tooltip-wrapper .MuiTooltip-arrow {
    color: #000;
  }

.elevate-header-sensitive-tooltip {
  position: relative;
  display: inline-block
}

.elevate-header-sensitive-tooltip .ehst-tooltip-text {
    font-size: 1em;
    color: #fff;
    background-color: #000;
    position: absolute;
    padding: 0.21875rem 0.5rem 0.3125rem 0.5rem;
    white-space: nowrap;
    border-radius: 0.5625em;
    transition-property: opacity, transform;
    transition-duration: 0.3s;
    transition-delay: 0.6s;
    transition-timing-function: ease-in-out;
    z-index: 2;
    pointer-events: none;
    opacity: 0;
    left: 0;
    top: 50%;
    transform: translate(-80%, -50%);
    text-align: left
  }

.elevate-header-sensitive-tooltip .ehst-tooltip-text:after {
      content: '';
      position: absolute;
      display: inline-block;
      width: 0;
      height: 0;
      border: 0.25em solid transparent;
      border-left-color: #000;
      top: 50%;
      transform: translateY(-50%);
      left: 100%;
      overflow: visible;
    }

.elevate-header-sensitive-tooltip:hover .ehst-tooltip-text {
      pointer-events: all;
      opacity: 1;
      transition-delay: 0s;
      transform: translate(-110%, -50%);
    }
